import * as React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import Theme from "../../../Theme/ThemeOptions";

interface IProps extends ButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

interface IState {
}

const Primary = styled(({ ...props }: IProps) => (
    <Button {...props} />
))<IProps>(() => ({
    margin: 0,
    padding: "10px 20px",
    background: Theme.palette.primary.main,
    borderRadius: "2.5rem",
    color: Theme.palette.background.paper,
    '&:hover': {
        color: Theme.palette.primary.main,
        background: Theme.palette.background.paper
    },
    '&:disabled': {
        background: Theme.palette.text.disabled,
        pointerEvents: "none",
    },
}));

const Secondary = styled(({ ...props }: IProps) => (
    <Button {...props} />
))<IProps>(() => ({
    margin: 0,
    padding: "10px 20px",
    background: Theme.palette.background.paper,
    borderRadius: "2.5rem",
    border: `2px solid ${Theme.palette.text.primary}`,
    color: Theme.palette.text.primary,
    '&:hover': {
        background: Theme.palette.background.default,
    },
    '&:disabled': {
        opacity: "75%",
        pointerEvents: "none",
    },
}));

const Text = styled(({ ...props }: IProps) => (
    <Button {...props} />
))<IProps>(() => ({
    margin: 0,
    padding: 0,
    background: "",
    color: Theme.palette.text.primary,
    '&:hover': {
        color: Theme.palette.primary.main,
    },
    '&:disabled': {
        opacity: "75%",
        pointerEvents: "none",
    },
}));

export class PrimaryButton extends React.Component<IProps, IState> {
    render() {
        return (
            <Primary {...this.props}
                onClick={this.props.onClick}>
                {this.props.children}
            </Primary>
        );
    }
}

export class SecondaryButton extends React.Component<IProps, IState> {
    render() {
        return (
            <Secondary {...this.props}
                onClick={this.props.onClick}>
                {this.props.children}
            </Secondary>
        );
    }
}

export class TextButton extends React.Component<IProps, IState> {
    render() {
        return (
            <Text {...this.props}
                onClick={this.props.onClick}>
                {this.props.children}
            </Text>
        );
    }
}