import React, { Component } from "react";
import { Typography, IconButton, Menu, MenuItem, Popover, TextField, PopoverOrigin, useTheme } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { BlockPicker, ColorChangeHandler } from "react-color";
import { styled, alpha } from "@mui/material/styles";
import { MenuView, IMenuItem } from "../General/Menu";
import { OptionModel } from "../../../Models/OptionModel";
import Theme from "../../../Theme/ThemeOptions";

interface IProps {
    top: number
}

interface IState {
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}

const Container = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IDiv>(({ active }) => ({
    width: "100%",
    height: "calc(100% - 30px)",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "0",
    left: "0",
    borderBottom:`1px solid ${Theme.palette.text.disabled}`,
}));

interface IBar extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}
const Bar = styled(({ active, ...divProps }: IBar) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IBar>(() => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexGrow: 1,
    alignItems: "center"
}));

interface ITransparent extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}
const Transparent = styled(({ active, ...divProps }: ITransparent) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<ITransparent>(({ active }) => ({
    height: "100%",
    borderLeft:`1px solid ${Theme.palette.text.primary}`,
    borderRight:`1px solid ${Theme.palette.text.primary}`,
    borderTop: `1px solid ${Theme.palette.text.disabled}`,
    flexGrow: 1,
}));

interface ILine extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}
const Line = styled(({ active, ...divProps }: ILine) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<ILine>(({ active }) => ({
    height: "1px",
    background: Theme.palette.text.primary,
    width: "100%",
    position: "relative",
    top: "50%",
}));



export class Background extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Container>
                <Bar>
                <Typography variant="body2" sx={{width: "20px"}}>
                    {Math.floor((this.props.top) / 10) * 10}
                </Typography>


                    <Transparent >
                        <Line />
                    </Transparent>
                </Bar>
                <Bar>
                    <Typography variant="body2" sx={{width: "20px"}}>
                        {Math.floor((this.props.top) / 10) * 8}
                    </Typography>


                    <Transparent >
                        <Line />
                    </Transparent>
                </Bar>
                <Bar>
                    <Typography variant="body2" sx={{width: "20px"}}>
                        {Math.floor((this.props.top) / 10) * 6}
                    </Typography>


                    <Transparent >
                        <Line />
                    </Transparent>
                </Bar>
                <Bar>
                    <Typography variant="body2" sx={{width: "20px"}}>
                        {Math.floor((this.props.top) / 10) * 4}
                    </Typography>


                    <Transparent >
                        <Line />
                    </Transparent>
                </Bar>
                <Bar>
                    <Typography variant="body2" sx={{width: "20px"}}>
                        {Math.floor((this.props.top) / 10) * 2}
                    </Typography>


                    <Transparent >
                        <Line />
                    </Transparent>
                </Bar>

                <Bar>
                    <Typography variant="body2" sx={{width: "20px"}}>
                        {0}
                    </Typography>


                    <Transparent >
                        <Line />
                    </Transparent>
                </Bar>


            </Container>
        );
    }
}