import React, { Component } from "react";
import { Tooltip, TooltipProps, useTheme } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Theme from "../../../Theme/ThemeOptions";

interface IProps extends TooltipProps {
    title: string
}

interface IState {
}

const Container = styled(({ ...tooltipProps }: IProps) => (
    <Tooltip {...tooltipProps} />
))<IProps>(() => ({
    margin: 0,
    position: "fixed",
    padding: "0.5rem 1rem",
    display: "flex",
    zIndex: 100,
    gap: "8px",
    flexDirection: "row",
    borderRadius: "2rem",
    background: Theme.palette.background.paper,
    boxShadow: `0 0 10px 10px ${alpha(Theme.palette.text.primary, 0.15)}`
}));

export class TooltipView extends Component<IProps, IState> {
    render() {
        return (
            <Tooltip {...this.props} enterDelay={500} leaveDelay={50} arrow title={this.props.title}>
                {this.props.children}
            </Tooltip>
        );
    }
}