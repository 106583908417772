import * as React from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { Typography, Popper, Paper, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles"
import 'material-icons/iconfont/material-icons.css';
import Theme from "../../../Theme/ThemeOptions";
import {TooltipView as Tooltip} from "../General/Tooltip"
import {MenuView as Menu, IMenuItem} from "../General/Menu";


interface IProps {
    description: string,
    groupId: string,
    id: string,
    index: number,
    isFavorite: boolean,
    title: string,
    userId: string,
    selected: boolean,
    onClick?: () => void,
}

interface IState {
   hover: boolean,
   anchor: Element | null,
   open: boolean,
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    selected?: boolean,
    innerRef: (element: HTMLElement | null) => any,
}

const InputContainer = styled(({ innerRef, ...divProps }: IDiv) => (
    <div {...divProps} ref={innerRef} />
), { shouldForwardProp: (prop) => prop !== "selected" })<IDiv>(({ selected }) => ({
    position: "relative",
    borderRadius: 4,
    boxSizing: "border-box",
    width: "100%",
    flexGrow: 1,
    verticalAlign: "top",
    background: "#fcfcfc",
    ...(selected ? { padding: 4, border: `4px solid${Theme.palette.text.primary}` } : { padding: 7, border: `1px solid${Theme.palette.text.disabled}` })
}));

export class DraggableInput extends React.Component<IProps, IState> {
    constructor(props: IProps | Readonly<IProps>) {
        super(props);
        this.state = {
            hover: false,
            anchor: null,
            open: false,
        };
    }

    InputMenu: Array<IMenuItem> = [
        { text: "Edit", callback: null },
        { text: "Duplicate", callback: null },
        { text: "Delete", callback: null }
    ];

    open= {
        true: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            this.setState({ open: true });
        },
        false:(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            this.setState({ open: false });

        }
    }

    render() {
        //const SELECTED = this.props.data.selected && this.props.data.selected.inputs && this.props.data.selected.inputs.findIndex(x => x.group === this.props.data.groupId && x.input === this.props.data.id) !== -1;
        return (
            <React.Fragment>
                <Draggable
                    draggableId={this.props.id}
                    index={this.props.index}
                    key={this.props.id}>
                    {(provided: DraggableProvided) => (
                        <InputContainer
                            id={this.props.id}
                            key={this.props.id}
                            selected={this.props.selected}
                            onClick={this.props.onClick ? this.props.onClick : undefined}
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>

                            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                <Typography sx={{display: "inline-block"}} variant="subtitle2">

                                    {this.props.title}
                                </Typography>

                                <IconButton sx={{float: "right", display: "inline-block"}} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({anchor: e.currentTarget})}>
                                <span className="material-icons-outlined" style={{fontSize: "18px"}}>more_vert</span>
                                </IconButton>
                                <Menu items={this.InputMenu} anchor={this.state.anchor} onClose={() => this.setState({anchor: null})} transformOrigin={{vertical: "center", horizontal: "right"}} anchorOrigin={{vertical: "center", horizontal: "right"}} />

                            </Box>
                            {
                                    this.props.title !== this.props.description &&
                                    (this.state.open
                                    ? <React.Fragment>
                                          <Typography sx={{display: "inline-block", padding: "10px"}} variant="body2">

                                              {this.props.description}
                                          </Typography>
                                          <IconButton onClick={this.open.false} sx={{bottom: "-10px", position: "absolute", left: "50%", transform: "translateX(-50%)", ":hover": {cursor: "pointer", color: "", background: "#00000000", boxShadow: "0 0 30px 30px rbga(0, 0, 0, 0.1) inset"}}}>
                                              <span className="material-icons-outlined" style={{fontSize: "18px"}}>expand_less</span>
                                          </IconButton>
                                            </React.Fragment>
                                        :
                                    <Tooltip placement="bottom" title={this.props.description}>
                                    <IconButton onClick={this.open.true} sx={{bottom: "-10px", position: "absolute", left: "50%", transform: "translateX(-50%)", ":hover": {cursor: "pointer", color: "", background: "#00000000", boxShadow: "0 0 30px 30px rbga(0, 0, 0, 0.1) inset"}}}>
                                    <span className="material-icons-outlined" style={{fontSize: "18px"}}>expand_more</span>
                                    </IconButton>
                                    </Tooltip>)
                            }

                        </InputContainer>
                    )}
                </Draggable>

                {
                    //this.popover( Classes )
                }
            </React.Fragment>
        );
    }
}

export class FullInput extends React.Component<IProps, IState> {
    constructor(props: IProps | Readonly<IProps>) {
        super(props);
        this.state = {
            description: props.description,
            groupId: props.groupId,
            id: props.id,
            index: props.index,
            isFavorite: props.isFavorite,
            title: props.title,
            userId: props.userId
        };
    }

    render() {
        //const SELECTED = this.props.data.selected && this.props.data.selected.inputs && this.props.data.selected.inputs.findIndex(x => x.group === this.props.data.groupId && x.input === this.props.data.id) !== -1;
        return (
            <React.Fragment>
                <Draggable
                    draggableId={this.props.id}
                    index={this.props.index}
                    key={this.props.id}>
                    {(provided: DraggableProvided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>

                            <Typography variant="subtitle1">

                                {this.props.title}
                            </Typography>

                            <span className="material-icons-outlined">more_vert</span>

                            <Typography
                                variant="body1">

                                {this.props.description}
                            </Typography>
                            {
                                //provided.placeholder
                            }

                        </div>
                    )
                    }
                </Draggable>

                {
                    //this.popover( Classes )
                }
            </React.Fragment>
        );
    }
}

const TransparentContainer = styled(({innerRef, ...divProps }: IDiv) => (
    <div {...divProps} ref={innerRef}/>
), { shouldForwardProp: (prop) => prop !== "selected" })<IDiv>(({ selected }) => ({
    position: "relative",
    borderRadius: 4,
    boxSizing: "border-box",
    width: "100%",
    flexGrow: 1,
    verticalAlign: "top",
    ...(selected ? {background: "#fcfcfc", padding: 4, border: `4px solid${Theme.palette.text.primary}` } : { padding: 7, border: `1px solid${Theme.palette.text.disabled}` })
}));

export class TransparentInput extends React.Component<IProps, IState> {
    constructor(props: IProps | Readonly<IProps>) {
        super(props);
        this.state = {
            hover: false,
            anchor: null,
            open: false,
        };
    }

    InputMenu: Array<IMenuItem> = [
        { text: "Edit", callback: null },
        { text: "Duplicate", callback: null },
        { text: "Delete", callback: null }
    ];

    render() {
        //const SELECTED = this.props.data.selected && this.props.data.selected.inputs && this.props.data.selected.inputs.findIndex(x => x.group === this.props.data.groupId && x.input === this.props.data.id) !== -1;
        return (
            <React.Fragment>
                <Draggable
                    draggableId={this.props.id}
                    index={this.props.index}
                    key={this.props.id}>
                    {(provided: DraggableProvided) => (
                        <TransparentContainer
                            id={this.props.id}
                            key={this.props.id}
                            onClick={this.props.onClick}
                            selected={this.props.selected}
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>

                            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                <Typography sx={{display: "inline-block"}} variant="subtitle2">

                                    {this.props.title}
                                </Typography>

                                <IconButton sx={{float: "right", display: "inline-block"}} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({anchor: e.currentTarget})}>
                                <span className="material-icons-outlined" style={{fontSize: "18px"}}>more_vert</span>
                                </IconButton>
                                <Menu items={this.InputMenu} anchor={this.state.anchor} onClose={() => this.setState({anchor: null})} transformOrigin={{vertical: "center", horizontal: "right"}} anchorOrigin={{vertical: "center", horizontal: "right"}} />
                                {
                                    this.props.title !== this.props.description &&
                                    <Tooltip placement="bottom" title={this.props.description}>
                                    <IconButton sx={{bottom: "-10px", position: "absolute", left: "50%", transform: "translateX(-50%)", ":hover": {cursor: "default", color: "", background: "#00000000"}}}>
                                    <span className="material-icons-outlined" style={{fontSize: "18px"}}>expand_more</span>
                                    </IconButton>
                                    </Tooltip>
                                }
                            </Box>
                            {
                                //provided.placeholder
                            }

                        </TransparentContainer>
                    )}
                </Draggable>

                {
                    //this.popover( Classes )
                }
            </React.Fragment>
        );
    }
}