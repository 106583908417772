import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { SessionController } from "../Controllers/SessionController";
import { GroupController } from "../Controllers/GroupController";
import { Handler as DataHandler, IBoardContent, IsBoardContent, IVoteContent, IsVoteContent } from "../Data/DataHandler";
import { ServerSentEventHandler } from "../Data/ServerSentEventHandler";
import { styled, alpha } from "@mui/material/styles";
import { PrimaryButton as Button, TextButton } from "./Components/General/Button";
import { DragStart } from "react-beautiful-dnd";
import { PhaseCard } from "./Components/Navigation/PhaseCard";
import { BoardCard } from "./Components/Navigation/BoardCard";
import { VoteCard } from "./Components/Navigation/VoteCard";
import { BoardView } from "./Components/Organize/BoardView";
import { BoardModel } from "../Models/BoardModel";
import { VoteModel } from "../Models/VoteModel";
import { ColumnView } from "./Components/Organize/ColumnView";
import { DraggableGroup } from "./Components/Organize/GroupView";
import { DraggableInput } from "./Components/Organize/InputView";
import { PointsVote } from "./Components/Voting/PointsVote";
import { SliderVote } from "./Components/Voting/SliderVote";
import Theme from "../Theme/ThemeOptions";
import { Toolbar } from "./Components/General/Toolbar";
import { TooltipView } from "./Components/General/Tooltip";
import { ParticipateView } from "./Components/Participate/Mobile";
import { Ballot } from "./Components/Participate/Ballot";
import { Refine } from "./Components/Participate/Refine";
import { MenuView as Menu } from "./Components/General/Menu";

interface IRouteParams {
    session: string
}

interface IProps extends RouteComponentProps<IRouteParams> { }

interface IState {
    dataHandler: DataHandler | null,
    eventHandler: ServerSentEventHandler | null,
    collapse: {
        navigation: boolean;
    },
    creationDateInUnix: number,
    id: string,
    lastOpenedInUnix: number,
    name: string,
    ownerId: string,
    active: {
        type: string,
        identity: string,
    },
    anchor: {
        toolbar_add: null | Element,
        toolbar_vote: null | Element,
    },
    rightSide: "" | "participate" | "slider" | "points" | "refine",
    dragType: "input" | "group",
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

const Container = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    position: "absolute",
    margin: 0,
    padding: 0,
    background: Theme.palette.background.default,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "0",
    width: "100%",
}));

interface ISide extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    collapsed: boolean
}

const Left = styled(({ ...divProps }: ISide) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "collapsed" })<ISide>(({ collapsed }) => ({
    margin: 0,
    padding: "20px",
    background: Theme.palette.background.default,
    borderRight: `1px solid ${Theme.palette.text.disabled}`,
    height: "100%",
    width: collapsed
        ? "10px"
        : "10%",
}));

const Middle = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    position: "relative",
    margin: 0,
    padding: 0,
    background: Theme.palette.background.default,
    height: "100%",
    flexGrow: 1,
}));

const Right = styled(({ ...divProps }: ISide) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "collapsed" })<ISide>(({ collapsed }) => ({
    margin: 0,
    padding: 0,
    background: Theme.palette.background.default,
    height: "100%",
    borderLeft: `1px solid ${Theme.palette.text.disabled}`,
    width: collapsed
        ? "10px"
        : "10%",
}));

export class SessionView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            dataHandler: null,
            eventHandler: null,
            collapse: {
                navigation: false,
            },
            active: {
                type: "",
                identity: "",
            },
            anchor: {
                toolbar_add: null,
                toolbar_vote: null,
            },
            rightSide: "",
            creationDateInUnix: 0,
            lastOpenedInUnix: 0,
            name: "",
            id: "",
            ownerId: "",
            dragType: "input"
        };
    }

    componentDidMount() {
        this.Connect();
    }

    async Connect() {
        const SESSION_ID = this.props.match.params.session;

        const SESSION = await SessionController.connect(SESSION_ID);

        if (SESSION === null || SESSION === undefined) {
            alert("Couldn't Connect to Session");
            return;
        }

        if (SESSION.id === SESSION_ID) {

            //TODO: Have Session Return Access Rights

            const DATA = new DataHandler({ creationDateInUnix: SESSION.creationDateInUnix, id: SESSION.id, lastOpenedInUnix: SESSION.lastOpenedInUnix, name: SESSION.name, ownerId: SESSION.ownerId, forceRender: this.forceRender });
            console.log("BAM");
            console.log(DATA);
            await DATA.GetData();
            console.log(DATA);

            const SSE = new ServerSentEventHandler({ creationDateInUnix: SESSION.creationDateInUnix, id: SESSION.id, lastOpenedInUnix: SESSION.lastOpenedInUnix, name: SESSION.name, ownerId: SESSION.ownerId, dataHandler: DATA });
            SSE.open();
            this.setState({ creationDateInUnix: SESSION.creationDateInUnix, lastOpenedInUnix: SESSION.lastOpenedInUnix, name: SESSION.name, id: SESSION.id, ownerId: SESSION.ownerId, dataHandler: DATA, eventHandler: SSE });
        }
    }

    forceRender = (handler: DataHandler) => {
        this.setState({dataHandler: handler});
    };

    drawer = {
        Left: {
            collapse: () => {
                this.setState({collapse: {navigation: !this.state.collapse.navigation}});
            },
        },
        Right: {
            Participate: () => {
                this.setState({ rightSide: "participate", anchor: { toolbar_add: null, toolbar_vote: null } });
            },
            Poll: (type: "slider" | "points") => {
                this.setState({ rightSide: type, anchor: { toolbar_add: null, toolbar_vote: null } });
            },
            Refine: () => {
                this.setState({ rightSide: "refine" });
            },
            Close: () => {
                this.setState({ rightSide: "" });
            },
        },
    };

    addGroup = async () => {
        const ACTIVE = this.state.active;
        const HANDLER = this.state.dataHandler;
        if (ACTIVE.type !== "board" || !HANDLER)
            return;

        const BOARD = HANDLER.GetBoard(ACTIVE.identity);
        if (!BOARD)
            return;

        const GROUPS = HANDLER.GetGroups(BOARD.id);
        if (!GROUPS || GROUPS.length < 2)
            return;

        await GroupController.create(this.state.id, BOARD.phaseId, BOARD.id, `Group ${GROUPS.length - 1}`, 0);
    };


    LoadBoard(event: React.ChangeEvent<HTMLButtonElement | MouseEvent>, id: string) {
        event.stopPropagation();
        const ACTIVE = this.state.active;
        console.log("vote");
        ACTIVE.type = "board";
        ACTIVE.identity = id;

        this.setState({ active: ACTIVE });
    }

    LoadVote(event: React.ChangeEvent<HTMLButtonElement | MouseEvent>, id: string) {
        event.stopPropagation();
        const ACTIVE = this.state.active;
        ACTIVE.type = "vote";
        ACTIVE.identity = id;

        this.setState({ active: ACTIVE });
    }

    menu = {
        items: {
            Toolbar_Add: [
                { text: "Input", callback: this.drawer.Right.Participate },
                { text: "Group", callback: this.addGroup }
            ],
            Toolbar_Vote: [
                { text: "Slider", callback: () => this.drawer.Right.Poll("slider") },
                { text: "Points", callback: () => this.drawer.Right.Poll("points") }
                ],
        },
        anchor: {
            Toolbar_Add: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                const ANCHOR = event.currentTarget;

                this.setState({ anchor: { toolbar_add: ANCHOR, toolbar_vote: null } });
            },
            Toolbar_Vote: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                const ANCHOR = event.currentTarget;

                this.setState({ anchor: { toolbar_add: null, toolbar_vote: ANCHOR } });
            },
            close: () => {
                this.setState({ anchor: { toolbar_add: null, toolbar_vote: null } });
            }
        }
    };

    onDragStart = (initial: DragStart) => {
        if(initial.type === "input") {
            this.setState({dragType: "input"});
        } else if (initial.type === "group") {
            this.setState({dragType: "group"});
        }

    };

    InviteClipboard = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        //TODO: Change this to Domain Link
        const LINK = `localhost:44339/s/${this.state.id}`;
        navigator.clipboard.writeText(LINK);
    };


    render() {
        const HANDLER: DataHandler | null = this.state.dataHandler;
        const USER = localStorage.getItem("user");
        if (HANDLER) {
            const ACTIVE = this.state.active;

            let Display: VoteModel | BoardModel | undefined;
            let Content: IBoardContent | IVoteContent | undefined;

            if (ACTIVE.type === "board") {
                Display = HANDLER.GetBoard(ACTIVE.identity);
                if (Display) {
                    Content = HANDLER.GetBoardContent(Display.id);
                }
            }
            else if (ACTIVE.type === "vote") {
                Display = HANDLER.GetVote(ACTIVE.identity);
                if (Display) {
                    Content = HANDLER.GetVoteContent(Display.id);
                }
            }

            return (
                <Container>

                    <Left
                        collapsed={this.state.collapse.navigation}>

                       <IconButton
                           size="small"
                           sx={{
                               position: "absolute",
                               top: "5px",
                               zIndex: "100",
                               right: "0",
                               transform: "translateX(50%)",
                               borderRadius: "50%",
                               background: "#DBDBDB",
                               border: `1px solid ${Theme.palette.text.secondary}`,
                               transition: "all 0.5s",
                               ":hover": { background: "#DBDBDB", boxShadow: "0 0 30px 30px rgba(0,0,0,0.1) inset" }
                           }}

                           onClick={this.drawer.Left.collapse}
>

                           {this.state.collapse.navigation
                                ? <span
                                className="material-icons-outlined" style={{ fontSize: "18px" }}>chevron_right</span>
                                : <span
                                className="material-icons-outlined" style={{ fontSize: "18px" }}>chevron_left</span>
                           }
                       </IconButton>


                        <Typography
                            variant="h3">
                            {this.state.name}
                        </Typography>

                        {
                            HANDLER.GetPhases().map((phase) =>
                                <PhaseCard
                                    key={phase.id} {...phase}>
                                    {
                                        HANDLER.GetBoards(phase.id).map((board) =>
                                            <BoardCard
                                                active={ACTIVE.type === "board" && ACTIVE.identity === board.id}
                                                key={board.id} {...board}
                                                onClick={(e: React.ChangeEvent<HTMLButtonElement | MouseEvent>) => this.LoadBoard(e, board.id)}
                                                refined={false}>
                                                {
                                                    HANDLER.GetVotes(board.id).map((vote) =>
                                                        <VoteCard {...vote}  active={ACTIVE.type === "vote" && ACTIVE.identity === vote.id}

                                                            onClick={(e:React.ChangeEvent<HTMLButtonElement | MouseEvent>) => this.LoadVote(e ,vote.id)}
                                                            key={vote.id} />
                                                    )
                                                }
                                            </BoardCard>
                                        )
                                    }
                                </PhaseCard>
                            )
                        }

                        <Button
                            onClick={() => HANDLER.NewPhaseBoard()}>
                            +
                        </Button>

                    </Left>

                    <Middle>
                        {ACTIVE.type === "board" &&
                            <Toolbar
                                style={{ bottom: "10px", right: "10px" }}>

                                <TooltipView
                                    title="Add: (input, group, ...)">
                                    <div>
                                        <IconButton
                                            sx={{ width: "50px" }}
                                            onClick={this.menu.anchor.Toolbar_Add}>
                                            <span
                                                className="material-icons-outlined">
                                                timer
                                            </span>
                                        </IconButton>
                                    </div>
                                </TooltipView>

                                {(Display as BoardModel).lockData ?
                             <TooltipView
                                 title="Unlock Board">
                                 <IconButton
                                     sx={{ width: "50px" }}
                                     onClick={this.menu.anchor.Toolbar_Vote}>
                                     <span class="material-icons">
                                         lock
                                     </span>
                                 </IconButton>
                             </TooltipView>

                             :<TooltipView
                                  title="Lock Board">
                                  <IconButton
                                      sx={{ width: "50px" }}
                                      onClick={this.menu.anchor.Toolbar_Vote}>
                                      <span class="material-icons">
                                          lock_open
                                      </span>
                                  </IconButton>
                              </TooltipView>
                                }


                                <TooltipView
                                    title="Focus on Selected ...">

                                    <IconButton
                                        sx={{ width: "50px" }}>
                                        <span class="material-icons">
                                            screen_share
                                        </span>
                                    </IconButton>
                                </TooltipView>
                            </Toolbar>
}


                        {ACTIVE.type === "board" &&
                            <Toolbar
                             style={{ bottom: "10px", right: "350px" }}>

                             <TooltipView
                                 title="Add: (input, group, ...)">
                                 <div>
                                     <IconButton
                                         sx={{ width: "50px" }}
                                         onClick={this.menu.anchor.Toolbar_Add}>
                                         <div>
                                             <span
                                                 className="material-icons-outlined">
                                                 add_box
                                             </span>
                                         </div>
                                     </IconButton>
                                 </div>
                             </TooltipView>

                             <Menu
                                 transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                 items={this.menu.items.Toolbar_Add}
                                 onClose={this.menu.anchor.close}
                                 anchor={this.state.anchor.
                                     toolbar_add} />

                             <TooltipView
                                 title="Create Vote from Inputs">
                                 <IconButton
                                     sx={{ width: "50px" }}
                                     onClick={this.menu.anchor.Toolbar_Vote}>
                                     <div>
                                         <span
                                             className="material-icons-outlined">
                                             how_to_vote
                                         </span>
                                     </div>
                                 </IconButton>
                             </TooltipView>

                             <Menu
                                 transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                 items={this.menu.items.Toolbar_Vote}
                                 onClose={this.menu.anchor.close}
                                 anchor={this.state.
                                     anchor.toolbar_vote} />

                             <TooltipView
                                 title="Focus on Selected ...">

                                 <IconButton
                                     sx={{ width: "50px" }}>
                                     <div>
                                         <span
                                             className="material-icons-outlined">
                                             filter_center_focus
                                         </span>
                                     </div>
                                 </IconButton>
                             </TooltipView>

                             <TooltipView
                                 title="Take Inputs to Refined">

                                 <IconButton
                                     sx={{ width: "50px" }}
                                     onClick={this.drawer.Right.Refine}>
                                     <div>
                                         <span
                                             className="material-icons-outlined">
                                             filter_alt
                                         </span>
                                     </div>
                                 </IconButton>
                             </TooltipView>
                         </Toolbar>
}
                        {ACTIVE.type === "vote" &&
                            <Toolbar
                             style={{ bottom: "10px", right: "350px" }}>


                             <TooltipView
                                 title="Vote on options">
                                 <IconButton
                                     sx={{ width: "50px" }}
                                     onClick={this.drawer.Right.Participate}>
                                     <div>
                                         <span
                                             className="material-icons-outlined">
                                             how_to_vote
                                         </span>
                                     </div>
                                 </IconButton>
                             </TooltipView>



                         </Toolbar>
}

                        {
                            ACTIVE.type === "board"
                            && Display
                            && Content
                            && IsBoardContent(Content)
                            && <BoardView
                                dragType={this.state.dragType}
                                onDragStart={this.onDragStart}
                                session={this.state.id}
                                handler={HANDLER}
                                buffer={Content.buffer}
                                bufferChildren={HANDLER.GetInputs(Content.buffer.id)}
                                stack={Content.stack}
                                stackChildren={HANDLER.GetInputs(Content.stack.id)}
                                {...Display as BoardModel}>

                                {
                                    Content.columns.map((column, index) =>
                                        <ColumnView
                                            dragType={this.state.dragType}
                                            id={`Column-${index}`}
                                            index={index}
                                            key={`Column-${index}`}>

                                            {
                                                column.map((group) =>
                                                    <DraggableGroup
                                                        sessionId={this.state.id} phaseId={(Display as BoardModel).phaseId} {...group}
                                                        key={group.id}>

                                                        {
                                                            HANDLER.GetInputs(group.id).map((input) =>
                                                                <DraggableInput onClick={() => HANDLER.Select(ACTIVE.identity, input.id)} selected={HANDLER.IsSelected(ACTIVE.identity, input.id)} {...input}
                                                                    key={input.id} />
                                                            )
                                                        }
                                                    </DraggableGroup>
                                                )
                                            }
                                        </ColumnView>
                                    )
                                }
                                {Content
                            && IsBoardContent(Content)
                            &&<Toolbar
                                     style={{pointerEvents: "auto", top: "10px", left: `calc(20px${this.state.collapse.navigation ? "" : " + 10% "}${Content.stack.isCollapsed ? " + 40px" : Content.buffer.isCollapsed ? " + 732px" : " + 366px"})`, position: "fixed" }}>


                                     <TooltipView
                                         title="Go back to Dashboard">
                                         <IconButton
                                             sx={{ width: "50px" }}
                                             onClick={() => this.props.history.push("/dashboard")}>

                                             <span class="material-icons-outlined">
                                                 home
                                             </span>

                                         </IconButton>
                                     </TooltipView>

                                     <TooltipView
                                         title="Copy Invite Link to Clipboard">
                                         <IconButton
                                             sx={{ width: "50px" }}
                                             onClick={this.InviteClipboard}>
                                             <span class="material-icons-outlined">
                                                 share
                                             </span>
                                         </IconButton>
                                     </TooltipView>


                                 </Toolbar>}
                            </BoardView>
                        }

                        {
                            ACTIVE.type === "vote"
                            && Display
                            && Content
                            && IsVoteContent(Content)
                            && (((Display as VoteModel).type === "points")
                                ? <PointsVote {...Display as VoteModel} {...Content} />
                                : <SliderVote {...Display as VoteModel} {...Content} />)
                        }
                    </Middle>
                    <Right
                        collapsed={this.state.rightSide === ""}>
                        {ACTIVE &&
                            this.state.rightSide === "participate"
                            && <ParticipateView
                                board={ACTIVE.type === "board"
                                    ? (Display as BoardModel)
                                    : undefined}
                                options={ACTIVE.type === "vote"
                                    ? HANDLER.GetOptions(ACTIVE.identity)
                                    : undefined}
                                session={this.state.id}
                            contributions={USER && ACTIVE.type === "board" ? HANDLER.GetContributions(ACTIVE.identity, USER) : undefined}
                                stack={(ACTIVE.type === "board"
                                    && Content
                                    && IsBoardContent(Content))
                                    ? Content.stack
                                    : undefined}
                                type={Display ? ACTIVE.type === "board"
                                    ? (Display as BoardModel).lockData
                                        ? "locked"
                                        : "board"
                                    : (Display as VoteModel).type
                                    : "locked"}
                                user={USER
                                    ? USER
                                    : ""}
                                vote={ACTIVE.type === "vote"
                                    ? (Display as VoteModel)
                                    : undefined} />
                        }
                        {
                            ACTIVE && ACTIVE.type === "board" &&
                                (this.state.rightSide === "slider" ||this.state.rightSide === "points")
                            && <Ballot session={this.state.id} phase={(Display as BoardModel).phaseId} board={(Display as BoardModel).id} type={this.state.rightSide} onClose={this.drawer.Right.Close} selected={HANDLER.GetSelected(ACTIVE.identity)} onRemove={(input:string) => HANDLER.Select(ACTIVE.identity, input)}/>
                        }
                        {
                            ACTIVE && ACTIVE.type === "board" &&
                                this.state.rightSide === "refine" &&
                                <Refine session={this.state.id} handler={HANDLER} phase={(Display as BoardModel).phaseId} board={(Display as BoardModel).id} onClose={this.drawer.Right.Close} selected={HANDLER.GetSelected(ACTIVE.identity)} onRemove={(input:string) => HANDLER.Select(ACTIVE.identity, input)} />
                        }
                        {
                            this.state.rightSide !== ""
                                && <TextButton onClick={this.drawer.Right.Close} sx={{position: "absolute", right: "5px", top: "5px"}}>
                                X
                                </TextButton>
                        }
                    </Right>

                </Container>
            );
        }
        else {
            return (
                <Container>
                    Loading Session...

                </Container>
            );
        }
    }
}