import { SessionModel } from "../Models/SessionModel";
import { PhaseModel } from "../Models/PhaseModel";
import { BoardModel } from "../Models/BoardModel";
import { GroupModel } from "../Models/GroupModel";
import { InputModel } from "../Models/InputModel";
import { VoteModel } from "../Models/VoteModel";
import { OptionModel } from "../Models/OptionModel";
import { TicketModel } from "../Models/TicketModel";
import { Handler as DataHandler } from "./DataHandler"
import { SessionRoutes } from "../Controllers/Routes";


interface IProps {
    creationDateInUnix: number,
    id: string,
    lastOpenedInUnix: number,
    name: string,
    ownerId: string,
    dataHandler: DataHandler,
}

interface IState {
    creationDateInUnix: number,
    id: string,
    lastOpenedInUnix: number,
    name: string,
    ownerId: string,
}



export class ServerSentEventHandler {

    creationDateInUnix: number;
    id: Readonly<string>;
    lastOpenedInUnix: number;
    name: string;
    ownerId: string;
    eventSource: null | EventSource = null;
    handler: DataHandler;


    constructor( props: IProps ) {

        this.creationDateInUnix = props.creationDateInUnix;
        this.id = props.id;
        this.lastOpenedInUnix = props.lastOpenedInUnix;
        this.name = props.name;
        this.ownerId = props.ownerId;
        this.handler = props.dataHandler;

    }


    parse = {
        session: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );
            const DATA: SessionModel = JSON.parse( MESSAGE.data );
        },
        phase: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );
            const DATA: PhaseModel = JSON.parse( MESSAGE.data );

            if ( DATA.name === "DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!" ) {
                //TODO: Delete
            }
            else {
                this.handler.AddPhase( DATA );
            }
        },
        board: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );

            const DATA: BoardModel = JSON.parse( MESSAGE.data );
            if ( DATA.phaseId === "DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!" ) {
                //TODO: Delete

            }
            else {
                this.handler.AddBoard( DATA );
            }
        },
        group: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );

            const DATA: GroupModel[] | GroupModel = JSON.parse( MESSAGE.data );


            if ( Array.isArray( DATA ) ) {

                this.handler.AddGroups( DATA );
            }
            else {
                if ( DATA.boardId === "DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!" ) {

                    //TODO: Delete
                }
                else {
                    this.handler.AddGroup( DATA );
                }
            }
        },
        input: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );

            const DATA: InputModel[] | InputModel = JSON.parse( MESSAGE.data );
            if ( Array.isArray( DATA ) ) {

                this.handler.AddInputs( DATA );
            }
            else {
                if ( DATA.groupId === "DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!" ) {

                    //TODO: Delete
                }
                else {
                    this.handler.AddInput( DATA );
                }
            }
        },
        vote: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );

            const DATA: VoteModel = JSON.parse( MESSAGE.data );
            if ( DATA.boardId === "DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!" ) {

                //TODO: Delete
            }
            else {
                this.handler.AddVote( DATA );
            }
        },
        option: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );
            const DATA: OptionModel = JSON.parse( MESSAGE.data );
            if ( DATA.voteId === "DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!" ) {

                //TODO: Delete
            }
            else {
                this.handler.AddOption( DATA );
            }
        },
        ticket: ( event: Event ) => {
            const MESSAGE = ( event as MessageEvent );

            const DATA: TicketModel = JSON.parse( MESSAGE.data );
            if ( DATA.voteId === "DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!" ) {

                //TODO: Delete
            }
            else {
                this.handler.AddTicket( DATA );
            }
        }
    };

    open = ( ) => {

        const SESSION = this.id;
        this.close( );

        this.eventSource = new window.EventSource( SessionRoutes.connect( SESSION ) );

        this.eventSource.addEventListener( "session", this.parse.session );


        this.eventSource.addEventListener( "active", ( ) => {

            //TODO: Make this part of the SessionEVENT
            //console.log(e);
            //console.log(e.lastEventId);
            //if (e.lastEventId !== UserId) {
            //    console.log("Inside");
            //    const Active = JSON.parse(e.data);
            //    const Phases = this.state.phases;

            //    for (let i = 0; i < Phases.length; i++) {
            //        const Index = Phases[i].boards.findIndex(x => x.id === Active.id);
            //        if (Index !== -1) {
            //            const Board = Phases[i].boards[Index];
            //            Board.phaseId = Phases[i].id;
            //            this.setState({
            //                board: Board,
            //                vote: {
            //                    active: null,
            //                    creating: ""
            //                }
            //            });
            //            break;
            //        }
            //    }
            //}
        } );

        this.eventSource.addEventListener( "phase", this.parse.phase );

        this.eventSource.addEventListener( "board", this.parse.board );

        this.eventSource.addEventListener( "group", this.parse.group );

        this.eventSource.addEventListener( "input", this.parse.input );

        this.eventSource.addEventListener( "vote", this.parse.vote );

        this.eventSource.addEventListener( "option", this.parse.option );

        this.eventSource.addEventListener( "ticket", this.parse.ticket );

    };


    close = ( ) => {
        if ( this.eventSource !== undefined && this.eventSource !== null && this.eventSource.readyState !== window.EventSource.CLOSED ) {
            this.eventSource.close( );
        }
    };
}