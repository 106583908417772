import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PrimaryButton as Button, SecondaryButton, TextButton } from "./Components/General/Button";
import { SessionCard } from "./Components/Dashboard/SessionCard";
import { Typography, TextField, IconButton, Select, Divider, ListSubheader, MenuItem, Box, Checkbox, FormControlLabel } from "@mui/material";
import { SessionController } from "../Controllers/SessionController";
import { SessionModel } from "../Models/SessionModel";
import Theme from "../Theme/ThemeOptions";
import { styled, alpha } from "@mui/material/styles";


//function UseSessions(userId) {
//    const [Sessions, SetSessions] = useState(null);
//    useEffect(() => {

//        SetSessions(SessionService.get.owned(userId));
//    });

//    return Sessions;
//}

interface IProps extends RouteComponentProps { }

interface IState {
    sessions: SessionModel[],
    creating: boolean,
    title: string,
    description: string,
    access: "admin"  | "password" | "public",
    password: string,

}



export class DashboardView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            sessions: [],
            creating: false,
            title: "",
            description: "",
            access: "admin",
            password: ""
        };
    }


    async componentDidMount() {
        const UserId = localStorage.getItem("user");
        if (UserId) {
            const Sessions = await SessionController.get.owned(UserId);

            if (Sessions) {
                this.setState({
                    sessions: Sessions
                });
            }
        }
    }


    async CreateSession(event: React.MouseEvent<HTMLButtonElement | MouseEvent>) {
        event.preventDefault();
        const Sessions: SessionModel[] = this.state.sessions;
        const UserId = localStorage.getItem("user");
        const Title = this.state.title;
        if (UserId) {
            const Session: SessionModel | null = await SessionController.create(Title, UserId);

            if (Session) {
                Sessions.push(Session as SessionModel);

                this.setState({
                    sessions: Sessions,
                    creating: false
                });
            }
        }

        //this.LoadSession(Session.Id);
    }


    async LoadSession(id: string) {
        this.props.history.push(`/s/${id}`);
    }


    HandleTitle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const VALUE = event.target.value;

        this.setState({ title: VALUE });
    };

    HandleDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const VALUE = event.target.value;

        this.setState({ description: VALUE });
    };


    unixToDate(unix: number) {
        //TODO: Move this out of this class
        const Time = new Date(unix);

        let month = "";
        switch (Time.getMonth() + 1) {
            case 1:
                month = "January";
                break;
            case 2:
                month = "February";
                break;
            case 3:
                month = "March";
                break;
            case 4:
                month = "April";
                break;
            case 5:
                month = "May";
                break;
            case 6:
                month = "June";
                break;
            case 7:
                month = "July";
                break;
            case 8:
                month = "August";
                break;
            case 9:
                month = "September";
                break;
            case 10:
                month = "October";
                break;
            case 11:
                month = "November";
                break;
            case 12:
                month = "December";
                break;
        }

        return `${Time.getDate()}. ${month} ${Time.getFullYear()}`;
    }


    create = {
        open: () => {
            this.setState({ creating: true });
        },
        close: () => {
            this.setState({ creating: false });
        }
    };


    render() {
        //TODO: Get userID from JWT or/as Cookie
        const Creating = this.state.creating;
        const Sessions = this.state.sessions;
        return Creating
                   ? (//Creating == true
                       <form>
                           <Box
                               sx={{ width: "100%", height: "100%", position: "absolute", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>

                               {/*   <TextButton sx={{position: "absolute", left: "calc(50% - max(min(40%, 500px), 250px) - calc(50px + 2.5%))", top: "calc(50% - 360px)"}}*/}
                               {/*    onClick={() => this.create.close()}>*/}
                               {/*    <span class="material-icons-outlined">*/}
                               {/*        keyboard_backspace*/}
                               {/*    </span> Back*/}
                               {/*</TextButton>*/}

                           <Box
                               sx={{ width: "100%",height: "50%", minHeight: "375px", background: Theme.palette.background.paper, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                               <Box
                                   sx={{ minWidth: "500px", width:"1000px", maxWidth: "80%", height: "100%",position: "relative", display: "flex", flexDirection: "column", gap: "10px", justifyContent: "end" }}>
                                   <Typography
                                       variant="h2">
                                       New session settings
                                   </Typography>

                                   <Typography sx={{marginBottom: "20px"}}
                                       variant="body1">
                                       Some explanation here about session settings :)
                                   </Typography>

                                   <TextField
                                       fullWidth
                                       id="code"
                                       InputLabelProps={{ shrink: true, color: "primary",  }}
                                       label="Session Title"
                                       name="code"

                                       placeholder="type session title here..."
                                       sx={{
                                    '.MuiInputLabel-shrink': {
                                        color: Theme.palette.text.primary,
                                        fontFamily: "Lato, Roboto, Arial",
                                        fontSize: '1.25rem',
                                        margin: "0 -10px",
      lineHeight: 1.25,
      fontWeight: 700,
                                    },
                                    '.MuiOutlinedInput-root': {
                                         outline: "none !important",
                                         padding: "15px 0"
                                    },
                                    '.MuiOutlinedInput-input': {
                                        border: `2px solid ${alpha(Theme.palette.text.disabled, 0.5)}`,
                                        padding: "10px",
                                        borderRadius: "5px"
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        outline: "none",
                                        border: "none"
                                    }
                                }}
                                       onChange={this.HandleTitle}
                                       value={this.state.title}
                                       variant="outlined" />

                                   <TextField
                                       fullWidth
                                       id="code"
                                       InputLabelProps={{ shrink: true }}
                                       label="Session Description (optional)"
                                       multiline
                                       name="code"
                                       onChange={this.HandleDescription}
                                       rows={3}
                                    placeholder="type session description here..."
                                sx={{
                                    '.MuiInputLabel-shrink': {
                                        color: Theme.palette.text.primary,
                                        fontFamily: "Lato, Roboto, Arial",
                                        fontSize: '1.25rem',
                                        margin: "0 -10px",
      lineHeight: 1.25,
      fontWeight: 700,
                                    },
                                    '.MuiOutlinedInput-root': {
                                         outline: "none !important",
                                         padding: "15px 0"
                                    },
                                    '.MuiOutlinedInput-input': {
                                        border: `2px solid ${alpha(Theme.palette.text.disabled, 0.5)}`,
                                        padding: "10px",
                                        borderRadius: "5px"
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        outline: "none",
                                        border: "none"
                                    }
                                }}
                                       value={this.state.description}
                                       variant="outlined" />
                                   <FormControlLabel
                                       control={<Checkbox />}
                                       label="Show description in invite" />
                               </Box>
                           </Box>



                        <Box
                               sx={{ width: "100%",height: "50%", maxHeight: "calc(100% - 375px)", background: Theme.palette.background.default, position: "sticky", top: "0", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                               <Box
                                   sx={{ minWidth: "500px", width:"1000px", maxWidth: "80%", height: "100%", display: "flex", flexDirection: "column", gap: "10px", padding: "10px", justifyContent: "start", alignItems: "center"}}>
                                   <Typography
                                       variant="h4">
                                       Security
                                   </Typography>

                                   <Typography sx={{marginBottom: "20px"}}
                                       variant="body1">
                                       Some explanation here about privacy settings such that you don't need to explain each point :)
                                   </Typography>

                                   <Box sx={{border: "2px solid #dadada", background: "#dadada", borderRadius: "0.5rem", display: "flex", flexDirection: "row", alignSelf: "start", height: "54px"}}><Typography sx={{background: "#eaeaea", width: "150px", textAlign: "start", border: "solid #dadada", borderWidth: "0 1px 0 0", borderRadius: "6px 0 0 6px", height: "50px", lineHeight: "50px", boxSizing: "border-box", padding: "0 20px" }} variant="body1">Access</Typography>
                                   <Select value={this.state.access} variant="outlined" sx={{width: "150px", height: "50px", boxSizing: "border-box", borderRadius: "0 6px 6px 0", border: "solid #dadada", borderWidth: "0 0 0 1px", background: Theme.palette.background.paper, color: Theme.palette.primary.main}}>
                                    <MenuItem value="admin" onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => this.setState({access: "admin"})}>Admin only</MenuItem>
                                       <MenuItem value="password" onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => this.setState({access: "password"})}>Password</MenuItem>
                                       <MenuItem value="public" onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => this.setState({access: "public"})}>Public</MenuItem>
                                    </Select>
                                   </Box>
                                {this.state.access === "password" && <TextField
                                                                         id="code"
                                                                         InputLabelProps={{ shrink: true, color: "primary" }}
                                                                         label="Session Password"

                                    type="password"
                                                                         name="code"
                                    required
                                                                         sx={{
                                    '.MuiInputLabel-shrink': {
                                        color: Theme.palette.text.primary,
                                        fontFamily: "Lato, Roboto, Arial",
                                        fontSize: '1.25rem',
                                        margin: "0 -10px",
      lineHeight: 1.25, display: "none", opacity: "0",
      fontWeight: 700,
                                    },
                                    '.MuiOutlinedInput-root': {
                                         outline: "none !important",
                                         padding: "15px 0"
                                    },
                                    '.MuiOutlinedInput-input': {
                                        border: `2px solid ${alpha(Theme.palette.text.disabled, 0.5)}`,
                                        padding: "10px",
                                        borderRadius: "5px"
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        outline: "none",
                                        border: "0 !important"
                                    }
                                }}
                                                                         onChange={this.HandleDescription}

                                                                         value={this.state.password}
                                                                         variant="outlined" />
                                }

                                   <Box sx={{display: "flex", flexDirection: "row", gap: "50px", justifyContent: "center", alignSelf: "center"}}>
                                    <SecondaryButton onClick={() => this.setState({creating: false})}>Cancel</SecondaryButton>
                                    <Button onClick={(event) => this.CreateSession(event)}>Create Session</Button>
                                    </Box>
                               </Box>
                           </Box>

                           </Box>
                       </form>
                   )
                   : (//Creating == false
                       <Box sx={{display: "flex", flexDirection: "column", }}>

                           <Box sx={{}}>
                               <Box sx={{display:"flex", flexDirection: "row", alignItems: "center"}}>
                                   <Typography>
                                       Welcome back, {localStorage.getItem("name")}!
                                   </Typography>
                                   <Button
                                       onClick={() => this.create.open()}>
                                       Create new boost<span class="material-icons">add_circle</span>
                                   </Button>
                               </Box>
                               <Typography>
                                   Public sessions
                               </Typography>
                           </Box>

                           <Box>

                               <Typography>
                                   All sessions
                               </Typography>

                               <Box>

                                   <Box>
                                       <Select
                                           autoWidth
                                           defaultValue={2}
                                           variant="outlined">
                                           <ListSubheader>SORT BY</ListSubheader>
                                           <MenuItem
                                               value={1}>
                                               Alphabetical
                                           </MenuItem>
                                           <MenuItem
                                               value={2}>
                                               Date Modified
                                           </MenuItem>
                                           <MenuItem
                                               value={3}>
                                               Date Created
                                           </MenuItem>

                                           <ListSubheader >ORDER</ListSubheader>
                                           <MenuItem
                                               value={4}>
                                               Ascending
                                           </MenuItem>
                                           <MenuItem
                                               value={5}>
                                               Descending
                                           </MenuItem>

                                       </Select>
                                   </Box>

                                   <Box>

                                       <IconButton>
                                           <span class="material-icons-outlined">
                                               create_new_folder
                                           </span>
                                       </IconButton>

                                       <Divider
                                           orientation="vertical" />

                                       <IconButton>
                                           <span class="material-icons-outlined">
                                               apps
                                           </span>
                                       </IconButton>

                                       <IconButton>
                                           <span class="material-icons-outlined">
                                               format_list_bulleted
                                           </span>
                                       </IconButton>

                                   </Box>

                               </Box>

                               <Box>
                                   {Sessions.map(session => {
                                       return(<SessionCard {...session}
                                                  onClick={(id) => this.LoadSession(id)} />
                                       );
                                   })}
                               </Box>
                           </Box>

                       </Box>
                   );
    }
}