import * as React from "react";
import { Route } from "react-router";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./Theme/ThemeOptions";
import { SessionView } from "./View/SessionView";
import { DashboardView } from "./View/DashboardView";
import { LandingView } from "./View/LandingView";

import "./custom.css"


export class App extends React.Component {


    render () {
        return (
            <React.Fragment>
                <ThemeProvider
                    theme={Theme}>
                    <Route
                        component={SessionView}
                        path="/s/:session" />
                    <Route
                        component={DashboardView}
                        path="/dashboard" />
                    <Route
                        component={LandingView}
                        exact
                        path="/" />

                </ThemeProvider>
            </React.Fragment>
        );
    }
}