import Axios from "axios";
import {UserRoutes} from "./Routes";

export interface IResult {
    id: string,
    refreshToken: string,
}


export const UserController = {
    login: async (email:string, password:string) => {
        const Data = {
            Email: email,
            Password: password
        };
        let result: null | IResult = null;

        await Axios.post(UserRoutes.login(), Data).then((res) => {
            result = res.data as IResult;
        }, (error) => {
            alert("Login Failed");
        });

        return result;

    },
    register: async (company:string, email:string, firstName:string, lastName:string, password:string, phoneNumber:string) => {
        const Data = {
            Company: company,
            Email: email,
            FirstName: firstName,
            LastName: lastName,
            Password: password,
            PhoneNumber: phoneNumber
        };
        let result: boolean = false;

        await Axios.post(UserRoutes.register(), Data).then((res) => {
            alert("Registered Succesfully, Login to continue");
            result = true;
        }, (error) => {
            alert("Register attempt Failed");
        });

        return result;
    },
    refresh: async (jwt:string, refresh:string) => {
        const Data = {
            Token: jwt,
            RefreshToken: refresh
        };
        let result: null | IResult = null;

        await Axios.post(UserRoutes.refresh(), Data).then((res) => {

            result = res.data as IResult;
        }, (error) => {
            console.log(error);
            alert(error);
        });

        return result;
    }
};