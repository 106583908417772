import * as React from "react";
import { Typography, Divider, TextField } from "@mui/material";
import { TextButton, PrimaryButton } from "../General/Button";
import { GroupModel } from "../../../Models/GroupModel";
import { InputModel } from "../../../Models/InputModel";
import { BoardController } from "../../../Controllers/BoardController";
import { InputController } from "../../../Controllers/InputController";
import { GroupController } from "../../../Controllers/GroupController";
import {Handler} from "../../../Data/DataHandler";


interface IProps {
    selected?:InputModel[],
    groups?: GroupModel[],
    onSubmit?: ( refined: { groups: GroupModel[], inputs: InputModel[] } ) => void,
    onRemove: ( inputId: string ) => void,
    onClose: ( ) => void,
    session: string,
    phase: string,
    board: string,
    handler: Handler,

}

interface IState { }

export class Refine extends React.Component<IProps, IState> {
    state = {
        votes: [ ],
        title: "",
        description: "",
    };


    handleChange = ( event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
        const Name = event.currentTarget.name;
        const Value = event.currentTarget.value;

        this.setState( {
            ...this.state,
            [Name]: Value
        } );

    };


    onSubmit = ( event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
        event.preventDefault( );

        this.SubmitAsync();


    };

    async SubmitAsync() {
        const SESSION = this.props.session;
        const PHASE = this.props.phase;
        const UserId = localStorage.getItem("user");
        if (!UserId)
            return;

        const NewGroups: { from: string, to: string }[] = [];

        const BOARD = await BoardController.create(UserId, SESSION, PHASE, this.state.title, this.state.description);
        if (!BOARD)
            return;

        await GroupController.create(SESSION, PHASE, BOARD.id, "Stack", 0);
        await GroupController.create(SESSION, PHASE, BOARD.id, "Buffer", 0);

        const SELECTED = this.props.selected;
        if (!SELECTED)
            return;

        for (let i = 0; i < SELECTED.length; i++) {
            const IsCreated = NewGroups.findIndex(x => x.from === SELECTED[i].groupId);

            if (IsCreated !== -1) {

                await InputController.create(SESSION, PHASE, BOARD.id, NewGroups[IsCreated].to, SELECTED[i].title, SELECTED[i].description, SELECTED[i].userId);
            }
            else {
                const GROUP = this.props.handler.GetGroup(SELECTED[i].groupId);
                if (GROUP) {
                    const ToGroup = await GroupController.create(SESSION, PHASE, BOARD.id, GROUP.name, GROUP.column);
                    if (ToGroup) {
                        await GroupController.setColor(SESSION, PHASE, BOARD.id, ToGroup.id, GROUP.color);

                        const FromTo = { from: GROUP.id, to: ToGroup.id };
                        NewGroups.push(FromTo);

                        await InputController.create(SESSION, PHASE, BOARD.id, ToGroup.id, SELECTED[i].title, SELECTED[i].description, SELECTED[i].userId);
                    }
                }
            }
        }

    }


    render( ) {
        return (
            <div>
                <form
                    onSubmit={ this.onSubmit }>

                    <Typography
                        variant="subtitle2">
                        Filter Board
                    </Typography>

                    <Divider />

                    <div>
                        <div style={{padding: "10px"}}>
                            <TextField
                                InputLabelProps={{ required: false }}
                                label="Title"
                                name="title"
                                onChange={this.handleChange}
                                size="small"
                                type="text"
                                value={this.state.title}
                                variant="outlined" />

                            <TextField
                                InputLabelProps={{ required: false }}
                                label="Description"
                                multiline
                                name="description"
                                onChange={this.handleChange}
                                required
                                type="text"
                                value={this.state.description}
                                variant="outlined" />

                        </div>
                        <Typography
                            variant="subtitle1">

                            Selection
                        </Typography>


                        <div>

                            <Typography
                                variant="body1">

                                Inputs
                            </Typography>

                            {
                                this.props.selected
                                    && this.props.selected.map( ( input ) =>
                                        <div style={{display: "flex", flexDirection: "row", }}
                                            key={`${ input.id }-${ input.groupId }`}>

                                            <div>
                                                <TextButton
                                                    onClick={( ) => this.props.onRemove( input.id ) }>
                                                    X
                                                </TextButton>
                                            </div>

                                            <Typography variant="subtitle2">

                                                { input.title }
                                            </Typography>

                                        </div>
                                    )
                            }
                        </div>

                        <div>

                            <Typography variant="overline">

                                Groups
                            </Typography>

                            {
                                //this.props.selected.groups
                                //    && this.props.selected.groups.map( ( group ) =>
                                //        <div
                                //            key={ group.id }>


                                //            <div>

                                //                <TextButton
                                //                    onClick={( ) => this.props.onRemove( group.id )}>
                                //                    X
                                //                </TextButton>
                                //            </div>

                                //            <Typography >

                                //                { group.name }
                                //            </Typography>

                                //        </div>
                                //    )
                            }

                        </div>

                    </div>

                    <Divider />

                    <div>
                        <div>
                            <PrimaryButton
                                onClick={( e ) => this.onSubmit( e ) }>
                                Filter to new board!
                            </PrimaryButton>
                        </div>

                        <div>
                            <TextButton
                                onClick={ ( ) => this.props.onClose( ) }>
                                preview
                            </TextButton>
                        </div>

                    </div>
                </form>
            </div>
        );
    }
}