import React from "react";
import Axios from "axios";
import { OptionRoutes } from "./Routes";
import { OptionModel } from "../Models/OptionModel"

export const OptionController = {
    async create(sessionId: string, phaseId: string, boardId: string, voteId: string, inputId: string, inputTitle: string) {
        const DATA = {
            InputId: inputId,
            Title: inputTitle
        };
        let Result: OptionModel | null = null;

        await Axios.post(OptionRoutes.create(sessionId, phaseId, boardId, voteId), DATA).then(res => {
            if (res.status === 201) {
                Result = res.data;
            }
        }, err => {
            if (err.status === 400) {
                alert(err.error);
            }
        });

        return Result;
    },

    async remove(sessionId: string, phaseId: string, boardId: string, voteId: string, optionId: string) {
        let Result: boolean = false;

        await Axios.delete(OptionRoutes.remove(sessionId, phaseId, boardId, voteId, optionId)).then(res => {
            if (res.status === 204) {
                Result = true;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },

    async setColor(sessionId: string, phaseId: string, boardId: string, voteId: string, optionId: string, hexColor: string) {
        const DATA = {
            ColorInHex: hexColor
        };
        let Result: OptionModel | null = null;

        await Axios.patch(OptionRoutes.setColor(sessionId, phaseId, boardId, voteId, optionId), DATA).then(res => {
            Result = res.data;
        }, err => {
            alert(err.error);
        });

        return Result;
    },

    get: {
        byVote: async (sessionId: string, phaseId: string, boardId: string, voteId: string) => {
            let Result: Array<OptionModel> = [];

            await Axios.get(OptionRoutes.getAllInVote(sessionId, phaseId, boardId, voteId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        bySession: async (sessionId: string) => {
            let Result: Array<OptionModel> = [];

            await Axios.get(OptionRoutes.getAll(sessionId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        byId: async (sessionId: string, phaseId: string, boardId: string, voteId: string, optionId: string) => {
            let Result: OptionModel | null = null;

            await Axios.get(OptionRoutes.getById(sessionId, phaseId, boardId, voteId, optionId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    }
};