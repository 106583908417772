import * as React from "react";
import { Typography, IconButton, Box } from "@mui/material";
import { Draggable, Droppable, DraggableProvided, DroppableProvided } from "react-beautiful-dnd";
import "material-icons/iconfont/material-icons.css";
import { styled } from "@mui/material/styles"
import { DraggableInput, TransparentInput } from "./InputView";
import { InputModel } from "../../../Models/InputModel";
import { GroupModel } from "../../../Models/GroupModel";
import Theme from "../../../Theme/ThemeOptions";
import { InputController } from "../../../Controllers/InputController";
import { GroupController } from "../../../Controllers/GroupController";
import { Handler } from "../../../Data/DataHandler";


export interface IStackProps {
    session: string,
    phaseId: string,
    stack: GroupModel,
    stackChildren: InputModel[],
    buffer: GroupModel,
    bufferChildren: InputModel[],
    handler: Handler,
}

interface IState {
    flip: boolean,
}

interface ISwissCheese {
    id: string,
    index: number,
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

const Container = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    bottom: 0,
    position: "sticky",
    padding: "24px 24px 24px 10px",
    background: "#DBDBDB",
    height: "100%",
}));

const Inputs = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    display: "flex",
    height: "calc(100% - 76px)",
    overflowY: "scroll",
    overflowX: "hidden",
    '&::-webkit-scrollbar': {
        display: "none"
    },
    '-ms-overflow-style': "none",
    'scrollbar-width': "none",
    flexDirection: "column",
    gap: "10px"
}));

const TopHalf = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    display: "flex",
    flexDirection: "column",
}));

const BottomHalf = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    minHeight: "5%",
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
}));

export class StackView extends React.Component<IStackProps, IState> {
    state = {
        flip: false
    };


    RenderSwissCheese(inputs: InputModel[], transparent: boolean) {
        let Index = 0;
        return inputs.map((input) => {
                if (input.index === Index) {
                    Index = input.index + 1;
                    return (
                        <div
                            id={input.id + "-container"}
                            key={input.id + "-container"}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: this.props.buffer.isCollapsed
                                           ? "50%"
                                           : "100%"
                            }}>
                            <Typography
                                sx={{ marginRight: "10px", width: "15px", textAlign: "center" }}
                                variant="subtitle1">
                                {input.index + 1}
                            </Typography>
                            {transparent
                                 ? <TransparentInput onClick={() => this.props.handler.Select(this.props.stack.boardId, input.id)} selected={this.props.handler.IsSelected(this.props.stack.boardId, input.id)} {...input} />
                                 : <DraggableInput onClick={() => this.props.handler.Select(this.props.stack.boardId, input.id)} selected={this.props.handler.IsSelected(this.props.stack.boardId, input.id)} {...input} />}

                        </div>
                    );
                }
                else {
                    return (this.CreateSwissCheese(input, Index).map(cheese => {
                        if (input.index === cheese.index) {
                            Index = input.index + 1;
                            return (
                                <div
                                    id={input.id + "-container"}
                                    key={input.id + "-container"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: this.props.buffer.isCollapsed
                                                   ? "50%"
                                                   : "100%"
                                    }}>
                                    <Typography
                                        sx={{ marginRight: "10px", width: "15px", textAlign: "center" }}
                                        variant="subtitle1">
                                        {input.index + 1}
                                    </Typography>
                                    {transparent
                                         ? <TransparentInput onClick={() => this.props.handler.Select(this.props.stack.boardId, input.id)} selected={this.props.handler.IsSelected(this.props.stack.boardId, input.id)} {...input} />
                                         : <DraggableInput onClick={() => this.props.handler.Select(this.props.stack.boardId, input.id)} selected={this.props.handler.IsSelected(this.props.stack.boardId, input.id)} {...input} />}
                                </div>
                            );
                        }
                        else {
                            return (
                                <div
                                    id={input.id + "-container"}
                                    key={input.id + "-container"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: this.props.buffer.isCollapsed
                                                   ? "50%"
                                                   : "100%"
                                    }}>
                                    <Typography
                                        sx={{ marginRight: "10px", width: "15px", textAlign: "center" }}
                                        variant="subtitle1">
                                        {cheese.index + 1}
                                    </Typography>
                                    <Draggable
                                        draggableId={cheese.id}
                                        index={cheese.index}
                                        isDragDisabled>
                                        {(provided: DraggableProvided) => (
                                            <div
                                                ref={provided.innerRef}
                                                style={{ flexGrow: 1, height: "56px" }} {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                            </div>
                                        )}
                                    </Draggable>

                                </div>
                            );
                        }
                    }));
                }
            }
        );
    }


    CreateSwissCheese(input: InputModel, index: number): (InputModel | ISwissCheese)[] {
        if (input.index <= index) {

            //alert(`Stack Cheese: Too Cheesy ${input.index} : ${index}`);
            return [input];
        }
        const PLACEHOLDER: (InputModel | ISwissCheese)[] = [];
        for (let I = index; I < input.index; I++) {
            const SWISS_CHEESE: ISwissCheese = { id: `cheese${I}-${input.id}`, index: I };
            PLACEHOLDER.push(SWISS_CHEESE);
        }
        PLACEHOLDER.push(input);
        return PLACEHOLDER;
    }

    CompressSwissCheese() {
        const SESSION = this.props.session;
        const PHASE = this.props.session;
        const BOARD = this.props.stack.boardId;

        InputController.compressSwissCheese(SESSION, PHASE, BOARD);
    }

    CollapseGroup = (id: string) => {
        const SESSION = this.props.session;
        const PHASE = this.props.session;
        const BOARD = this.props.stack.boardId;
        GroupController.collapse.single(SESSION, PHASE, BOARD, id)
    }

    StackRender() {
        return (
            <Droppable
                droppableId={this.props.stack.id}
                isDropDisabled
                type="input">
                {(provided: DroppableProvided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ height: "100%", display: "flex", flexDirection: this.props.buffer.isCollapsed ? "row wrap" : "column", gap: "10px" }}>

                        <div
                            style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", paddingTop: "20px" }}>


                            <Typography
                                sx={{ fontWeight: "500" }}
                                variant="subtitle1">

                                NEW INPUT
                            </Typography>

                            <div
                                style={{ borderTop: "2px dotted #707070", flexGrow: 1 }} />
                        </div>
                        {this.RenderSwissCheese(this.props.stackChildren, false)}


                    </div>
                )}
            </Droppable>
        );
    }


    BufferRender() {
        return(
            <Droppable
                droppableId={this.props.buffer.id}
                type="input">
                {(provided: DroppableProvided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ height: "100%", display: "flex", flexDirection: this.props.buffer.isCollapsed ? "row wrap" : "column", gap: "10px" }}>

                        <div
                            style={{ display: "flex",  flexDirection: "row", gap: "10px", alignItems: "center", paddingTop: "20px" }}>


                            <Typography
                                sx={{  fontWeight: "500" }}
                                variant="subtitle1">

                                BUFFERED INPUT
                            </Typography>

                            <div
                                style={{ borderTop: "2px dotted #707070", flexGrow: 1 }} />
                        </div>

                        {this.RenderSwissCheese(this.props.bufferChildren, true)}


                    </div>
                )}
            </Droppable>
        );
    }


    render() {
        return (
            <Container
                style={{
                    width: this.props.stack.isCollapsed
                               ? "40px"
                               : this.props.buffer.isCollapsed
                                 ? "732px"
                                 : "366px"
                }}>


                {!this.props.buffer.isCollapsed
                    && <IconButton
                           size="small"
                           sx={{
                               position: "absolute",
                               top: "5px",
                               zIndex: "100",
                               right: "0",
                               transform: "translateX(50%)",
                               borderRadius: "50%",
                               background: "#DBDBDB",
                               border: `1px solid ${Theme.palette.text.secondary}`,
                               transition: "all 0.5s",
                               ":hover": { background: "#DBDBDB", boxShadow: "0 0 30px 30px rgba(0,0,0,0.1) inset" }
                           }}

                           onClick={( ) => this.CollapseGroup( this.props.stack.id )}
>

                           {this.props.stack.isCollapsed
                                ? <span
                                className="material-icons-outlined" style={{ fontSize: "18px" }}>chevron_right</span>
                                : <span
                                className="material-icons-outlined" style={{ fontSize: "18px" }}>chevron_left</span>
                           }
                       </IconButton>
                }

                {!this.props.stack.isCollapsed
                    && <IconButton
                           size="small"
                           sx={{
                               position: "absolute",
                               top: "40px",
                               zIndex: "100",
                               right: "0",
                               transform: "translateX(50%)",
                               borderRadius: "50%",
                               background: "#DBDBDB",
                               border: `1px solid ${Theme.palette.text.secondary}`,
                               transition: "all 0.5s",
                               ":hover": { background: "#DBDBDB", boxShadow: "0 0 30px 30px rgba(0,0,0,0.1) inset" }
                           }}
                           onClick={( ) => this.CollapseGroup( this.props.buffer.id )}
>

                           {!this.props.buffer.isCollapsed
                                ? <span
                                className="material-icons-outlined" style={{ fontSize: "18px" }}>chevron_right</span>
                                : <span
                                className="material-icons-outlined" style={{ fontSize: "18px" }}>chevron_left</span>
                           }
                       </IconButton>
                }

                {!this.props.stack.isCollapsed
                    && <div
                           style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>


                           <Typography
                               variant="h4">

                               Stack
                           </Typography>
                           <Box
                               sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>

                               <IconButton size="small" sx={{borderRadius: "4px",background: "#DBDBDB",
                               border: `1px solid ${Theme.palette.text.secondary}`,
                               transition: "all 0.5s",
                               ":hover": { background: "#DBDBDB", boxShadow: "0 0 30px 30px rgba(0,0,0,0.1) inset" }}}
                                   onClick={() => this.setState({ flip: !this.state.flip })}>

                                   <span
                                       class="material-icons">
                                       swap_vert
                                   </span>
                               </IconButton>

                               <IconButton onClick={() => this.CompressSwissCheese()} size="small" sx={{borderRadius: "4px",background: "#DBDBDB",
                               border: `1px solid ${Theme.palette.text.secondary}`,
                               transition: "all 0.5s",
                               ":hover": { background: "#DBDBDB", boxShadow: "0 0 30px 30px rgba(0,0,0,0.1) inset" }}}>

                                   <span
                                       className="material-icons-outlined">
                                       compress
                                   </span>
                               </IconButton>
                           </Box>
                       </div>
                }


                {!this.props.stack.isCollapsed
                    && <Inputs>

                           <TopHalf>
                               {
                                   this.state.flip
                                       ? this.BufferRender()
                                       : this.StackRender() //Default
                               }

                           </TopHalf>
                           <BottomHalf>
                               {
                                   this.state.flip
                                       ? this.StackRender()
                                       : this.BufferRender() //Default

                               }

                           </BottomHalf>
                       </Inputs>
                }
            </Container>
        );
    }
}