import * as React from "react";
import { Typography, IconButton, Menu, MenuItem, Popover, TextField, PopoverOrigin} from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { BlockPicker, ColorChangeHandler } from "react-color";
import { styled, alpha } from "@mui/material/styles";
import { MenuView, IMenuItem } from "../General/Menu";
import Theme from "../../../Theme/ThemeOptions";

interface IProps {
    creationDateInUnix: number,
    id: string,
    lastOpenedInUnix: number,
    name: string,
    ownerId: string,
    onClick: (id: string) => Promise<void>,

}

interface IState {
    hover: boolean,
    collapsed: boolean,
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
   active?: boolean,
}

const Container = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" && prop !== "theme" })<IDiv>(({ active, theme}) => ({
    width: "200px",
    height: "120px",
    position: "relative",
    border: `1px solid ${Theme.palette.text.disabled}`,
    transition: "background 1s, box-shadow 1s",
    '&:hover': {
        background: alpha(Theme.palette.primary.main, 0.4),
        boxShadow: `0 0 30px 60px ${alpha(Theme.palette.background.paper, 0.4)} inset`
    }
}));

const CardHeader = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IDiv>(({ active, theme }) => ({
    width: "100%",
    position: "relative",
    height: "40px",
    display: "flex",
    justifyContent: "end",

}));

const CardBody = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" && prop !== "theme"  })<IDiv>(({ active, theme }) => ({
    width: "100%",
    height: "80px",
    position: "relative",
}));

export class SessionCard extends React.Component<IProps, IState> {
    state = {
        hover: false,
        collapsed: false,
    }

    BoardMenu: Array<IMenuItem> = [
        { text: "Rename", callback: null },
        { text: "Delete", callback: null }
    ];

    LastEditString(last: number) {
        const Last = last * 1000;
        const Now = Date.now();
        let Time = Now - Last;
        if (Time < 10000) {
            return "Edited just now";
        }
        else {
            Time /= 1000;
            if (Time < 120) {
                return `Edited ${Math.floor(Time)} seconds ago`;
            }
            else {
                Time /= 60;
                if (Time < 120) {
                    return `Edited ${Math.floor(Time)} minutes ago`;
                }
                else {
                    Time /= 60;
                    if (Time < 72) {
                        return `Edited ${Math.floor(Time)} hours ago`;
                    }
                    else {
                        Time /= 24;
                        if (Time < 14) {
                            return `Edited ${Math.floor(Time)} days ago`;
                        }
                        else {
                            Time /= 7;
                            if (Time < 8) {
                                return `Edited ${Math.floor(Time)} weeks ago`;
                            }
                            else {
                                Time /= 4;
                                if (Time < 24) {
                                    return `Edited ${Math.floor(Time)} months ago`;
                                }
                                else {
                                    Time /= 12;
                                    return `Edited ${Math.floor(Time)} years ago`;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //TODO: Draggable cards?
    render() {
        return (
            <Container
                onMouseEnter={(event) => this.setState({ hover: true })}
                onMouseLeave={(event) => this.setState({ hover: false })} onClick={() => this.props.onClick(this.props.id)}>
                <CardHeader>
                  <IconButton disabled>
                      <span class="material-icons-outlined">
                          admin_panel_settings
                      </span>
                        </IconButton>
                    <IconButton disabled>
                        <span class="material-icons-outlined">
                            more_vert
                        </span>
                    </IconButton>

                </CardHeader>
                <CardBody >
                    <Typography variant="body1">
                        {this.props.name}
                    </Typography>
                    <Typography variant="body2">
                        {this.LastEditString(this.props.lastOpenedInUnix)}
                    </Typography>
                </CardBody>
            </Container>
        );
    }
}