import * as React from "react";
import { Typography, IconButton, Menu, MenuItem, Popover, TextField, PopoverOrigin, useTheme, Slider, Box } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { BlockPicker, ColorChangeHandler } from "react-color";
import { styled, alpha } from "@mui/material/styles";
import { MenuView, IMenuItem } from "../General/Menu";
import { OptionModel } from "../../../Models/OptionModel";
import Theme from "../../../Theme/ThemeOptions";


interface IProps {
    color: string,
    inputId: string,
    id: string,
    total: number,
    title: string,
    averageRating: number,
    voteId: string,
    height: string,
    highNumber?: number,
    lowNumber?: number,
}

interface ISlider {
    color: string,
    inputId: string,
    id: string,
    total: number,
    title: string,
    averageRating: number,
    voteId: string,
    highNumber: number,
    lowNumber: number,
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}

const Container = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IDiv>(({ active }) => ({
    width: "100%",
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
}));

interface IBar extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}
const Bar = styled(({ active, ...divProps }: IBar) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IBar>(({ active }) => ({
    width: "100%",
    flexGrow: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column"
}));

interface ITransparent extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}
const Transparent = styled(({ active, ...divProps }: ITransparent) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<ITransparent>(({ active }) => ({
    width: "100%",
    flexGrow: "1",
    position: "relative",
}));

interface IOpaque extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    height: string,
    color: string,
}
const Opaque = styled(({ ...divProps }: IOpaque) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "height" && prop !== "color" })<IOpaque>(({ color, height }) => ({
    width: "50%",
    marginLeft: "25%",
    position: "relative",
    height: height,
    zIndex: 5,
    borderRadius: "1rem 1rem 0 0",
    boxShadow: "8px -8px 16px 8px rgba(0, 0, 0, 0.2)",
    background: color
}));

export class OptionView extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            color: props.color,
            inputId: props.inputId,
            id: props.id,
            title: props.title,
            averageRating: props.averageRating,
            total: props.total,
            voteId: props.voteId,
        };
    }

    render() {
        return (
            <Container>
                <Bar>
                    <Transparent></Transparent>
                    <Opaque height={this.props.height} color={this.props.color}> </Opaque>
                </Bar>

                <Typography variant="subtitle2" sx={{height: "30px", lineHeight: "30px", textAlign: "center"}}>
                    {this.props.title}
                </Typography>
            </Container>
        );
    }
}

export class OptionSlider extends React.Component<ISlider> {
    constructor(props: ISlider) {
        super(props);
        this.state = {
            color: props.color,
            inputId: props.inputId,
            id: props.id,
            title: props.title,
            averageRating: props.averageRating,
            total: props.total,
            voteId: props.voteId,
        };
    }

    render() {
        return (
            <Container style={{boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.1)", padding: "10px", borderRadius: "1rem"}}>
                <Box sx={{position: "relative",}}>
                <Typography variant="subtitle2" sx={{display: "inline-block", height: "30px", lineHeight: "30px"}}>
                    {this.props.title}
                </Typography>

                <Typography variant="body2" sx={{display: "inline-block", position: "relative", height: "30px", lineHeight: "30px", textAlign: "center", left: "50%", transform: "translateX(-50%)"}}>
                    {this.props.averageRating ? this.props.averageRating : this.props.lowNumber }
                </Typography>
                </Box>
                <Slider max={this.props.highNumber} min={this.props.lowNumber} value={this.props.averageRating} sx={{color: this.props.color}} step={1} />
            </Container>
        );
    }
}