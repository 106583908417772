import React from "react";
import Axios from "axios";
import {PhaseRoutes} from "./Routes";
import { PhaseModel } from "../Models/PhaseModel";


export const PhaseController = {
    async create(sessionId: string, name: string) {
        const DATA = {
            Name: name
        };
        let Result = null;

        await Axios.post(PhaseRoutes.add(sessionId), DATA).then(res => {
            if (res.status === 201) {
                Result = res.data;
            }
        }, err => {
            if (err.status === 400) {
                alert(err.error);
            }
        });

        return Result;
    },


    async delete(sessionId:string, phaseId:string) {
        let Result: boolean = false;

        await Axios.delete(PhaseRoutes.remove(sessionId, phaseId)).then(res => {
            if (res.status === 204) {
                Result = true;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },


    async rename(sessionId:string, phaseId:string, name:string) {
        const DATA = {
            Name: name
        };

        let Result: PhaseModel | null = null;

        await Axios.patch(PhaseRoutes.rename(sessionId, phaseId), DATA).then(res => {
            Result = res.data;
        }, err => {
            alert(err.error);
        });

        return Result;
    },


    get: {
        list: async (sessionId:string) => {
            let Result: Array<PhaseModel> = [];

            await Axios.get(PhaseRoutes.getList(sessionId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        single: async (sessionId:string, phaseId:string) => {
            let Result: PhaseModel | null = null;

            await Axios.get(PhaseRoutes.getSingle(sessionId, phaseId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    }
};