import { PhaseModel } from "../Models/PhaseModel";
import { BoardModel } from "../Models/BoardModel";
import { GroupModel } from "../Models/GroupModel";
import { InputModel } from "../Models/InputModel";
import { VoteModel } from "../Models/VoteModel";
import { OptionModel } from "../Models/OptionModel";
import { TicketModel } from "../Models/TicketModel";
import { PhaseController } from "../Controllers/PhaseController";
import { BoardController } from "../Controllers/BoardController";
import { GroupController } from "../Controllers/GroupController";
import { InputController } from "../Controllers/InputController";
import { VoteController } from "../Controllers/VoteController";
import { OptionController } from "../Controllers/OptionController";
import { TicketController } from "../Controllers/TicketController";


interface IProps {
    creationDateInUnix: number,
    id: string,
    lastOpenedInUnix: number,
    name: string,
    ownerId: string,
    forceRender: (data: Handler) => void,
}

interface IState {
    creationDateInUnix: number,
    id: string,
    lastOpenedInUnix: number,
    name: string,
    ownerId: string,
    forceRender: (data: Handler) => void,
    phases: Array<PhaseModel>,
    boards: Array<BoardModel>,
    groups: Array<GroupModel>,
    inputs: Array<InputModel>,
    votes: Array<VoteModel>,
    options: Array<OptionModel>,
    tickets: Array<TicketModel>,
    selected: Array<IBoardSelected>,
}

export interface IBoardContent {
    stack: GroupModel,
    buffer: GroupModel,
    columns: GroupModel[][],
}

export interface IVoteContent {
    tickets: TicketModel[],
    options: OptionModel[],
}

export interface IBoardSelected {
    board: string,
    selected: Array<string>,
}


export function IsBoardContent( object: any ): object is IBoardContent {
    return "stack" in object;
}


export function IsVoteContent( object: any ): object is IVoteContent {
    return "tickets" in object;
}


export class Handler {
    state: IState = {
        creationDateInUnix: 0,
        id: "",
        lastOpenedInUnix: 0,
        name: "",
        ownerId: "",
        forceRender: () => {
            return;
        },
        phases: [ ],
        boards: [ ],
        groups: [ ],
        inputs: [ ],
        votes: [ ],
        options: [ ],
        tickets: [ ],
        selected: [],
    };


    constructor( props: IProps | Readonly<IProps> ) {
        this.state = {
            creationDateInUnix: props.creationDateInUnix,
            id: props.id,
            lastOpenedInUnix: props.lastOpenedInUnix,
            name: props.name,
            ownerId: props.ownerId,
            forceRender: props.forceRender,
            phases: [ ],
            boards: [ ],
            groups: [ ],
            inputs: [ ],
            votes: [ ],
            options: [ ],
            tickets: [ ],
            selected: [],
        };
    }


    async GetData( ) {
        const SESSION = this.state.id;
        this.state.phases = await PhaseController.get.list( SESSION );

        this.state.boards = await BoardController.get.bySession( SESSION );

        this.state.groups = await GroupController.get.bySession( SESSION );

        this.state.inputs = await InputController.get.bySession( SESSION );

        this.state.votes = await VoteController.get.bySession( SESSION );

        this.state.options = await OptionController.get.bySession( SESSION );

        this.state.tickets = await TicketController.get.bySession( SESSION );

    }


    async NewPhaseBoard( ) {
        const SESSION = this.state.id;
        const USER = localStorage.getItem( "user" );
        if ( USER ) {
            const PHASE: PhaseModel | null = await PhaseController.create( SESSION, "Phase" );

            if ( PHASE ) {

                const BOARD: BoardModel | null = await BoardController.create( USER, SESSION, ( PHASE as PhaseModel ).id, "Board", "Description" );

                if ( BOARD ) {
                    const STACK: GroupModel | undefined = await GroupController.create( SESSION, ( PHASE as PhaseModel ).id, ( BOARD as BoardModel ).id, "Stack", 0 );

                    const BUFFERED: GroupModel | undefined = await GroupController.create( SESSION, ( PHASE as PhaseModel ).id, ( BOARD as BoardModel ).id, "Buffer", 0 );

                    if ( STACK && BUFFERED ) {
                        await this.GetData( );
                    }

                }
            }
        }
    }

    Select(boardId:string, inputId: string) {
        const HasBoard =  this.state.selected.findIndex(x => x.board === boardId);

        if (HasBoard !== -1) {
            const BOARD_SELECTED = this.state.selected[HasBoard];
            const IsSelected = BOARD_SELECTED.selected.findIndex(x => x === inputId);

            if (IsSelected !== -1) {
                BOARD_SELECTED.selected.splice(IsSelected, 1);
            }
            else {
                const INPUT = this.GetInput(inputId);
                if (INPUT) {
                    BOARD_SELECTED.selected.push(inputId);
                }
            }
        }
        else {
            const Board = this.GetBoard(boardId);
            const INPUT = this.GetInput(inputId);

            if (Board && INPUT) {
                const BOARD_SELECTED: IBoardSelected = {
                    board: boardId,
                    selected: [inputId]
                }

                this.state.selected.push(BOARD_SELECTED);
            }
        }

        this.state.forceRender(this);
    }

    IsSelected(boardId:string, inputId: string): boolean {
        const HasBoard =  this.state.selected.findIndex(x => x.board === boardId);

        if (HasBoard !== -1) {
            const BOARD_SELECTED = this.state.selected[HasBoard];
            const IsSelected = BOARD_SELECTED.selected.findIndex(x => x === inputId);

            if (IsSelected !== -1) {
                return true;
            }

        }

        return false;
    }

    ClearSelected(boardId: string) {
        const HasBoard =  this.state.selected.findIndex(x => x.board === boardId);

        if (HasBoard !== -1) {
            this.state.selected.splice(HasBoard, 1);
        }

        this.state.forceRender(this);
    }

    GetSelected(boardId: string) {
        const HasBoard =  this.state.selected.findIndex(x => x.board === boardId);

        if (HasBoard !== -1) {
            const BOARD_SELECTED = this.state.selected[HasBoard];
            let Inputs: InputModel[] = [];

            for (let i = 0; i < BOARD_SELECTED.selected.length; i++) {
                const INPUT = this.GetInput(BOARD_SELECTED.selected[i])

                if (INPUT){
                    Inputs.push(INPUT);
            }
            else {
                    //TODO: Input Doesn't exist, shouldn't be Selected!
                }
            }

            return Inputs;
        }
    }

    AddPhase(data: PhaseModel) {
        const PHASES = this.state.phases;
        const INDEX = PHASES.findIndex( x => x.id === data.id );

        if ( INDEX !== -1 ) {
            PHASES[ INDEX ] = data;
        }
        else {
            PHASES.push( data );
        }
        PHASES.sort( ( a, b ) => a.index - b.index );

        this.state.phases = PHASES;
        this.state.forceRender(this);
    }

    AddBoard(data: BoardModel) {
        const BOARDS = this.state.boards;
        const INDEX = BOARDS.findIndex( x => x.id === data.id );

        if ( INDEX !== -1 ) {
            BOARDS[ INDEX ] = data;
        }
        else {
            BOARDS.push( data );
        }
        BOARDS.sort( ( a, b ) => a.index - b.index );

        this.state.boards = BOARDS;
        this.state.forceRender(this);
    }

    AddGroup(data: GroupModel) {
        const GROUPS = this.state.groups;
        const INDEX = GROUPS.findIndex( x => x.id === data.id );
        if ( INDEX !== -1 ) {
            GROUPS[ INDEX ] = data ;
        }
        else {
            GROUPS.push( data );
        }
        GROUPS.sort( ( a, b ) => a.index - b.index );

        this.state.groups = GROUPS;
        this.state.forceRender(this);
    }

    AddGroups(data: GroupModel[]) {

                    const GROUPS = this.state.groups;

                    for ( let I = 0; I < data.length; I++ ) {
                        const INDEX = GROUPS.findIndex( x => x.id === data[ I ].id );
                        if ( INDEX !== -1 ) {
                            GROUPS[ INDEX ] = data[ I ];
                        }
                        else {
                            GROUPS.push( data[ I ] );
                        }
                    }
                    this.state.groups = GROUPS;



        this.state.forceRender(this);
    }

    AddInput(data: InputModel) {
        const INPUTS = this.state.inputs;
        const INDEX = INPUTS.findIndex( x => x.id === data.id );

        if ( INDEX !== -1 ) {
            INPUTS[ INDEX ] = data;
        }
        else {
            INPUTS.push( data );
        }
        INPUTS.sort( ( a, b ) => a.index - b.index );

        this.state.inputs = INPUTS;
        this.state.forceRender(this);
    }

    AddInputs(data: InputModel[]) {
        const INPUTS = this.state.inputs;

        for ( let I = 0; I < data.length; I++ ) {
            const INDEX = INPUTS.findIndex( x => x.id === data[ I ].id );
            if ( INDEX !== -1 ) {
                INPUTS[INDEX] = data[I];
            }
            else {
                INPUTS.push( data[ I ] );
            }
        }
        this.state.inputs = INPUTS;
        this.state.forceRender(this);
    }

    AddVote(data: VoteModel) {
        const VOTES = this.state.votes;
        const INDEX = VOTES.findIndex( x => x.id === data.id );

        if ( INDEX !== -1 ) {
            VOTES[ INDEX ] = ( data );
        }
        else {
            VOTES.push( data );
        }
        VOTES.sort( ( a, b ) => a.index - b.index );

        this.state.votes = VOTES;
        this.state.forceRender(this);
    }

    AddOption(data: OptionModel) {
        const OPTIONS = this.state.options;
        const INDEX = OPTIONS.findIndex( x => x.id === data.id );

        if ( INDEX !== -1 ) {
            OPTIONS[ INDEX ] = data;
        }
        else {
            OPTIONS.push( data );
        }

        this.state.options = OPTIONS;
        this.state.forceRender(this);
    }

    AddTicket(data: TicketModel) {
        const TICKETS = this.state.tickets;
        const INDEX = TICKETS.findIndex( x => x.id === data.id );

        if ( INDEX !== -1 ) {
            TICKETS[ INDEX ] = data;
        }
        else {
            TICKETS.push( data );
        }

        this.state.tickets = TICKETS;
        this.state.forceRender(this);
    }


    RemoveData( type: "phase" | "board" | "group" | "input" | "vote" | "option" | "ticket", id: string ) {
        const STATE = this.state;
        switch ( type ) {
            case "phase":
            {
                const INDEX = STATE.phases.findIndex( x => x.id === id );

                if ( INDEX !== -1 ) {
                    STATE.phases.splice( INDEX, 1 );
                }
                break;
            }
            case "board":
            {
                const INDEX = STATE.boards.findIndex( x => x.id === id );

                if ( INDEX !== -1 ) {
                    STATE.boards.splice( INDEX, 1 );
                }
                break;
            }
            case "group":
            {
                const INDEX = STATE.groups.findIndex( x => x.id === id );

                if ( INDEX !== -1 ) {
                    STATE.groups.splice( INDEX, 1 );
                }
                break;
            }
            case "input":
            {
                const INDEX = STATE.inputs.findIndex( x => x.id === id );

                if ( INDEX !== -1 ) {
                    STATE.inputs.splice( INDEX, 1 );
                }
                break;
            }
            case "vote":
            {
                const INDEX = STATE.votes.findIndex( x => x.id === id );

                if ( INDEX !== -1 ) {
                    STATE.votes.splice( INDEX, 1 );
                }
                break;
            }
            case "option":
            {
                const INDEX = STATE.options.findIndex( x => x.id === id );

                if ( INDEX !== -1 ) {
                    STATE.options.splice( INDEX, 1 );
                }
                break;
            }
            case "ticket":
            {
                const INDEX = STATE.tickets.findIndex( x => x.id === id );

                if ( INDEX !== -1 ) {
                    STATE.tickets.splice( INDEX, 1 );
                }
                break;
            }
        }

        this.state = STATE;
    }


    GetPhases( ): Array<PhaseModel> {
        return this.state.phases.sort((a,b) => a.index - b.index);
    }


    GetPhase( id: string ) {
        return this.state.phases.find( x => x.id === id );
    }


    GetBoards( phaseId: string ): Array<BoardModel> {
        return this.state.boards.filter( x => x.phaseId === phaseId ).sort((a,b) => a.index - b.index);
    }


    GetBoard( id: string ) {
        return this.state.boards.find( x => x.id === id );
    }


    GetBoardContent( boardId: string ): IBoardContent {
        const GROUPS = this.state.groups.filter( x => x.boardId === boardId );
        GROUPS.sort((a, b) => a.column - b.column !== 0
                              ? a.column - b.column
                              : a.index - b.index);

        const STACK_INDEX = GROUPS.findIndex( x => x.name === "Stack" );
        const BUFFER_INDEX = GROUPS.findIndex( x => x.name === "Buffer" );

        let Stack: GroupModel | undefined;
        let Buffer: GroupModel | undefined;

        if ( STACK_INDEX !== -1 ) {
            Stack = GROUPS[ STACK_INDEX ];
        }
        else {
            //TODO: Create Stack => it should ALWAYS exist => (but also can't before the board exists)
            alert( "Stack Doesn't Exist, Insert Stack Creation/Retrieval here" );
            throw Error( "I AM GOING TO LOSE MY FUCKING SHIT" );
        }



        if ( BUFFER_INDEX !== -1 ) {
            Buffer = GROUPS[ BUFFER_INDEX ];
        }
        else {
            //TODO: Create Buffer => it should ALWAYS exist => (But also can't before the board exists)
            alert( "Buffer Doesn't Exist, Insert Buffer Creation/Retrieval here" );
            throw Error( "I AM GOING TO LOSE MY FUCKING SHIT" );
        }

        const COLUMNS: Array<Array<GroupModel>> = [ ];

        for ( let I = 0; I < GROUPS.length; I++ ) {
            if ( I === STACK_INDEX || I === BUFFER_INDEX ) {
                continue;
            }


            while ( COLUMNS.length < GROUPS[ I ].column ) {
                const COLUMN: Array<GroupModel> = [ ];
                COLUMNS.push( COLUMN );
            }

            if ( COLUMNS.length === GROUPS[ I ].column ) {
                const COLUMN: Array<GroupModel> = [ ];
                COLUMN.push( GROUPS[ I ] );
                COLUMNS.push( COLUMN );
            }
            else {
                COLUMNS[ GROUPS[ I ].column ].push( GROUPS[ I ] );
            }
        }

        const CONTENT: IBoardContent = {
            columns: COLUMNS,
            stack: Stack,
            buffer: Buffer,
        };
        return CONTENT;
    }


    GetGroups( boardId: string ): Array<GroupModel> {
        return this.state.groups.filter( x => x.boardId === boardId );
    }


    GetGroup( id: string ) {
        return this.state.groups.find( x => x.id === id );
    }


    GetInputs( groupId: string ): Array<InputModel> {
        const INPUTS = this.state.inputs.filter( x => x.groupId === groupId );
        INPUTS.sort((a, b) => a.index - b.index);
        return INPUTS;
    }


    GetInput( id: string ) {
        return this.state.inputs.find( x => x.id === id );
    }

    GetContributions(boardId: string, userId: string) {
        const INPUTS = this.state.inputs.filter( x => x.boardId === boardId && x.userId === userId );
        return INPUTS;
    }


    GetVotes( boardId: string ): Array<VoteModel> {
        return this.state.votes.filter( x => x.boardId === boardId );
    }


    GetVote( id: string ) {
        return this.state.votes.find( x => x.id === id );
    }


    GetVoteContent( voteId: string ) {
        const OPTIONS = this.GetOptions( voteId );
        const TICKETS = this.GetTickets( voteId );

        const Vote = this.GetVote(voteId);

        if (Vote) {
            if (Vote.type === "points") {
                for (let k = 0; k < OPTIONS.length; k++) {
                    OPTIONS[k].total = 0;
                }

                for (let i = 0; i < TICKETS.length; i++) {
                    for (let j = 0; j < TICKETS[i].ratings.length; j++) {
                        const Index = OPTIONS.findIndex(x => x.id === TICKETS[i].ratings[j].key);

                        if (Index !== -1) {
                            OPTIONS[Index].total += TICKETS[i].ratings[j].value;
                        }
                    }
                }
            }
            else if (Vote.type === "slider") {
                for (let k = 0; k < OPTIONS.length; k++) {
                    OPTIONS[k].averageRating = 0;
                }

                for (let i = 0; i < TICKETS.length; i++) {
                    for (let j = 0; j < TICKETS[i].ratings.length; j++) {
                        const Index = OPTIONS.findIndex(x => x.id === TICKETS[i].ratings[j].key);

                        if (Index !== -1) {
                            OPTIONS[Index].averageRating += TICKETS[i].ratings[j].value;
                        }
                    }
                }

                for (let k = 0; k < OPTIONS.length; k++) {
                    OPTIONS[k].averageRating = Math.floor((OPTIONS[k].averageRating / TICKETS.length) * 10) / 10;
                }
            }
        }

        const CONTENT: IVoteContent = {
            options: OPTIONS,
            tickets: TICKETS,
        };
        return CONTENT;
    }


    GetOptions( voteId: string ): Array<OptionModel> {
        return this.state.options.filter( x => x.voteId === voteId );
    }


    GetOption( id: string ) {
        return this.state.options.find( x => x.id === id );
    }


    GetTickets( voteId: string ): Array<TicketModel> {
        return this.state.tickets.filter( x => x.voteId === voteId );

    }


    GetTicket( id: string ) {
        return this.state.tickets.find( x => x.id === id );
    }
}