import * as React from "react";
import { Typography, IconButton, Menu, MenuItem, Popover, TextField, Box } from "@mui/material";
import { DragDropContext, Droppable, DragStart, DropResult, ResponderProvided, DroppableProvided } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { styled } from "@mui/material/styles";
import { StackView, IStackProps } from "./StackView"
import Theme from "../../../Theme/ThemeOptions";
import { GroupController } from "../../../Controllers/GroupController";
import { InputController } from "../../../Controllers/InputController";
import {Handler} from "../../../Data/DataHandler";


interface IProps extends IStackProps {
    countdownEnabled: boolean,
    countdownEnd: number,
    countdownTime: number,
    description: string,
    id: string,
    index: number,
    lastUpdateUnix: number,
    lockData: boolean,
    phaseId: string,
    session: string,
    handler: Handler,
    title: string,
    dragType: "input" | "group",
    onDragStart: (initial: DragStart) => void,
}

interface IState {
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    innerRef?: (element: HTMLElement | null) => any,
    negative?: number,
}
const BoardContainer = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    margin: 0,
    position: "relative",
    display: "flex",
    padding: "50px 10px 10px 10px",

    background: Theme.palette.background.default,
    height: "100%",
    width: "100%",
}));

const OrganizedContainer = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
), {shouldForwardProp: prop => prop !== "negative"})<IDiv>(({negative}) => ({
    position: "relative",
    display: "flex",
    padding: "15px",
    flexGrow: 1,
    maxWidth: `calc(100% - ${negative}px)`,
    height: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
        display: "none"
    },
    '-ms-overflow-style': "none",
    'scrollbar-width': "none",
}));

interface IDrop extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    innerRef: (element: HTMLElement | null) => any,
    opened: boolean,
}

const NewColumnZone = styled(({ innerRef,  ...divProps }: IDrop) => (
    <div {...divProps} ref={innerRef}/>
), {shouldForwardProp: (prop) => prop !== "opened"})<IDrop>(({opened}) => ({
    height: "100%",
    ...(opened ? {flexGrow: 1, minWidth: "350px",} : {width: 0} )
}));

export class BoardView extends React.Component<IProps, IState> {
   constructor(props: IProps) {
       super(props);

   }


    onDragStart = (initial: DragStart) => {
        if(initial.type === "input") {
            this.setState({type: "input"});
        } else if (initial.type === "group") {
            this.setState({type: "group"});
        }

    }

    onDragEnd = ( result: DropResult ) => {

        if (!result.destination)
            return;

        if (result.type === "input") {
            if (result.destination.droppableId.startsWith("Col-")) {
                let Column: string = result.destination.droppableId.substring(4);
                if (Column === "End")
                    Column = `${React.Children.count(this.props.children)}`;

                this.onDragEndAsync.fromInput.toColumn(result.draggableId, result.source.droppableId, parseInt(Column));
            } else if (result.source.droppableId === result.destination.droppableId) {
                if (result.source.index === result.destination.index) {
                    return;
                }
                this.onDragEndAsync.fromInput.inGroup(result.source.droppableId, result.draggableId, result.destination.index);
            }
            else {
                this.onDragEndAsync.fromInput.toGroup(result.source.droppableId, result.draggableId, result.destination.droppableId, result.destination.index);
            }
        } else if (result.type === "group") {
            if (result.source.droppableId === result.destination.droppableId) {
                if (result.source.index === result.destination.index) {
                    return;
                }
                this.onDragEndAsync.fromGroup.inColumn(result.draggableId, result.destination.index);
            }
            else {
                let Column: string = result.destination.droppableId.split("-")[1];
                if (Column === "End")
                    Column = `${React.Children.count(this.props.children)}`;

                this.onDragEndAsync.fromGroup.toColumn(result.draggableId, parseInt(Column), result.destination.index);
            }
        }
    }

    onDragEndAsync = {
        fromInput: {
            toColumn: async (inputId: string, sourceGroup: string, columnIndex: number) => {
                const SESSION = this.props.session;
                const PHASE = this.props.phaseId;
                const BOARD = this.props.id;
                const INPUT = this.props.handler.GetInput(inputId);
                if (!INPUT)
                    return;

                const GROUP = await GroupController.create(SESSION, PHASE, BOARD, INPUT.title, columnIndex);
                if (!GROUP)
                    return;


                const DATA = [{ groupId: sourceGroup, inputId: inputId }];
                await InputController.set.group(SESSION, PHASE, BOARD, sourceGroup, DATA, GROUP.id, 0);
            },
            inGroup: async (groupId: string, inputId: string, destinationIndex: number) => {
                const SESSION = this.props.session;
                const PHASE = this.props.phaseId;
                const BOARD = this.props.id;

                const Group = this.props.handler.GetGroup(groupId);
                if (Group && Group.name === "Buffer") {
                    return;
                }

                await InputController.move(SESSION, PHASE, BOARD, groupId, inputId, destinationIndex);
            },
            toGroup: async(groupId: string, inputId: string, destinationGroup: string, destinationIndex: number) => {
                const SESSION = this.props.session;
                const PHASE = this.props.phaseId;
                const BOARD = this.props.id;

                const DATA = [{ groupId: groupId, inputId: inputId }];
                await InputController.set.group(SESSION, PHASE, BOARD, groupId, DATA, destinationGroup, destinationIndex);
            }
        },
        fromGroup: {
            inColumn: async (groupId: string, destinationIndex: number) => {
                const SESSION = this.props.session;
                const PHASE = this.props.phaseId;
                const BOARD = this.props.id;

                await GroupController.move(SESSION, PHASE, BOARD, groupId, destinationIndex);
            },
            toColumn: async (groupId: string, destinationColumn: number, destinationIndex: number) => {
                const SESSION = this.props.session;
                const PHASE = this.props.phaseId;
                const BOARD = this.props.id;

                await GroupController.setColumn(SESSION, PHASE, BOARD, groupId, destinationColumn, destinationIndex);
            }
        }
    }

    render() {
        return (
            <DragDropContext
                onDragEnd={this.onDragEnd} onBeforeDragStart={this.props.onDragStart}>

                <BoardContainer>
                    <Typography sx={{position: "absolute", top: "10px", left: "50%", transform: "translateX(-50%)"}} variant="h4">{this.props.title}</Typography>
                    <StackView session={this.props.session} phaseId={this.props.phaseId} handler={this.props.handler} stack={this.props.stack} stackChildren={this.props.stackChildren} buffer={this.props.buffer} bufferChildren={this.props.bufferChildren} />
                    <OrganizedContainer negative={this.props.stack.isCollapsed ? 40 : this.props.buffer.isCollapsed ? 732 : 366}>

                        {this.props.children}

                        <Droppable
                            droppableId={`Col-End`} type="input">
                            {(provided: DroppableProvided) => (
                                <NewColumnZone opened={this.props.dragType === "input"}
                                    innerRef={provided.innerRef}
                                    {...provided.droppableProps}>

                                    {provided.placeholder}
                                </NewColumnZone>
                            )}
                        </Droppable>
                        <Droppable
                            droppableId={`Column-End`} type="group">
                            {(provided: DroppableProvided) => (
                                <NewColumnZone opened={this.props.dragType === "group"}
                                    innerRef={provided.innerRef}
                                    {...provided.droppableProps}>

                                    {provided.placeholder}
                                </NewColumnZone>
                            )}
                        </Droppable>
                    </OrganizedContainer>

                </BoardContainer>
            </DragDropContext>
        );
    }
}