import React, {Component} from "react";
import {Typography, IconButton, Menu, MenuItem, Popover, TextField, PopoverOrigin} from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { BlockPicker, ColorChangeHandler } from "react-color";


interface IProps {
    variant: "h1" | "h2" | "h3" | "h4" | "body1" | "body2" | "subtitle1" | "subtitle2",
    submitChange: (text: string) => void,
    text: string,
}

interface IState {
    editing: boolean,
    text: string,
}

export class TypoField extends Component<IProps, IState> {
    state={
        editing: false,
        text: ""
    }

    HandleTitle = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.stopPropagation( );
        const VALUE = event.currentTarget.value;
        this.setState( {
            text: VALUE.toUpperCase()
        } );
    }

    HandleEdit = () => {
        this.setState({editing: true, text: this.props.text.toUpperCase()} );

        document.addEventListener( "keydown", this.HandleSubmit, false );
    }

    HandleSubmit = (event: KeyboardEvent) => {
        if ( event.key === "Enter" ) {
            event.preventDefault( );
            const TEXT = this.state.text;

            this.props.submitChange(TEXT );
            document.removeEventListener( "keydown", this.HandleSubmit, false );
            this.setState( { editing: false, text: ""  } );
        }
        else if (event.key === "Escape") {
            this.setState({ editing: false, text: "" });
            document.removeEventListener("keydown", this.HandleSubmit, false);
        }
        else if (!this.state.editing) {
            document.removeEventListener("keydown", this.HandleSubmit, false);
        }
    }

    render( ) {
        return (
            this.state.editing
                   ? <TextField
                         autoFocus
                         inputProps={{ autoFocus: true }}
                         InputProps={{ autoFocus: true }}
                         onChange={this.HandleTitle}
                         value={this.state.text.toUpperCase( )} />
                   :  <Typography variant={this.props.variant}
                           onDoubleClick={this.HandleEdit}>
                           {this.props.text.toUpperCase( )}
                       </Typography>
        );

    }
}