import * as React from "react";
import { Typography, IconButton, Menu, MenuItem, Popover, TextField, PopoverOrigin, useTheme } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { BlockPicker, ColorChangeHandler } from "react-color";
import { styled, alpha } from "@mui/material/styles";
import { MenuView, IMenuItem } from "../General/Menu";
import Theme from "../../../Theme/ThemeOptions";


const BoardMenu: Array<IMenuItem> = [
    { text: "Rename", callback: null },
    { text: "Delete", callback: null }
];

interface IProps {
    countdownEnabled: boolean,
    countdownEnd: number,
    countdownTime: number,
    countdownLock: boolean,
    lockData: boolean,
    description: string,
    id: string,
    index: number,
    highDescription: string,
    highNumber: number,
    lowDescription: string,
    lowNumber: number,
    hideResults: boolean,
    boardId: string,
    title: string,
    type: string,
}

interface IState {
    hover: boolean,
    collapsed: boolean,
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}

const Container = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IDiv>(({ active }) => ({
    width: "calc(100% - 25px)",
    position: "relative",
    marginLeft: "25px",
}));

const CardHeader = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IDiv>(({ active }) => ({
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    background: Theme.palette.background.paper,
    border: active
        ? `1px solid ${Theme.palette.primary.main}`
        : "",
    '&:hover': {
        background: Theme.palette.background.default,
    }
}));

const CardBody = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IDiv>(({ active }) => ({
    width: "100%",
    position: "relative",
}));

export class VoteCard extends React.Component<IProps, IState> {
    state = {
        hover: false,
        collapsed: false,
    }

    //TODO: Draggable cards?
    render() {
        return (
            <Container {...this.props} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                <CardHeader>
                    <IconButton disabled> <span className="material-icons-outlined">subdirectory_arrow_right</span> </IconButton>

                    <Typography variant="subtitle2">
                        {this.props.title}
                    </Typography>
                    {
                        this.state.hover
                            ? <React.Fragment>
                                <IconButton disabled>
                                    {
                                        this.props.lockData
                                            ? <span className="material-icons-outlined">lock</span>
                                            : <span className="material-icons-outlined">lock_open</span>
                                    }
                                </IconButton>
                                <IconButton disabled>
                                    <span className="material-icons-outlined">more_vert</span>
                                </IconButton>
                            </React.Fragment>
                            : <React.Fragment>
                                <IconButton disabled>
                                    {
                                        this.props.lockData
                                            ? <span className="material-icons-outlined">lock</span>
                                            : <span className="material-icons-outlined">lock_open</span>
                                    }
                                </IconButton>
                            </React.Fragment>
                    }

                </CardHeader>
            </Container>
        );
    }
}