import React from "react";
import Axios from "axios";
import {VoteRoutes} from "./Routes";
import {VoteModel} from "../Models/VoteModel"

export const VoteController = {
    async create(sessionId:string, phaseId:string, boardId:string, type:string, title:string, description:string, lowNumber:number, lowDesc:string, highNumber:number, highDesc:string) {
        const DATA = {
            Type: type,
            Title: title,
            Description: description,
            LowNumber: lowNumber,
            LowDescription: lowDesc,
            HighNumber: highNumber,
            HighDescription: highDesc
        };
        let Result: VoteModel | undefined;

        await Axios.post(VoteRoutes.create(sessionId, phaseId, boardId), DATA).then(res => {
            if (res.status === 201) {
                Result = res.data;
            }
        }, err => {
            if (err.status === 400) {
                alert(err.error);
            }
        });

        return Result;
    },


    async delete(sessionId:string, phaseId:string, boardId:string, voteId:string) {
        let Result: boolean = false;

        await Axios.delete(VoteRoutes.delete(sessionId, phaseId, boardId, voteId)).then(res => {
            if (res.status === 204) {
                Result = true;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },


    timer: {
        set: async (sessionId:string, phaseId:string, boardId:string, voteId:string, seconds:number) => {
            const DATA = {
                TimeInSeconds: seconds
            };

            let Result: VoteModel | null = null;
            await Axios.patch(VoteRoutes.setTimer(sessionId, phaseId, boardId, voteId), DATA).then(res => {
                if (res.status === 200) {
                    Result = res.data;
                }
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        start: async (sessionId:string, phaseId: string, boardId:string, voteId:string) => {
            let Result: VoteModel | null = null;
            await Axios.post(VoteRoutes.startTimer(sessionId, phaseId, boardId, voteId)).then(res => {
                if (res.status === 200) {
                    Result = res.data;
                }
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    },


    get: {
        byBoard: async (sessionId:string, phaseId:string, boardId:string) => {
            let Result: Array<VoteModel> = [];

            await Axios.get(VoteRoutes.getAllInBoard(sessionId, phaseId, boardId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        bySession: async ( sessionId: string ) => {
            let Result: Array<VoteModel> = [];

            await Axios.get(VoteRoutes.getAll(sessionId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        byId: async (sessionId:string, phaseId:string, boardId:string, voteId:string) => {
            let Result: VoteModel | null = null;

            await Axios.get(VoteRoutes.getById(sessionId, phaseId, boardId, voteId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    }
};