import * as React from "react";
import "material-icons/iconfont/material-icons.css";
import { Typography, TextField, Divider, Box, Slider } from "@mui/material";
import { InputModel } from "../../../Models/InputModel";
import { TicketModel } from "../../../Models/TicketModel";
import { VoteModel } from "../../../Models/VoteModel";
import { BoardModel } from "../../../Models/BoardModel";
import { OptionModel } from "../../../Models/OptionModel";
import { PrimaryButton as Button } from "../General/Button";
import { InputCard } from "./InputCard";
import { InputController } from "../../../Controllers/InputController";
import { TicketController } from "../../../Controllers/TicketController";
import {GroupModel} from "../../../Models/GroupModel";
import Theme from "../../../Theme/ThemeOptions";


interface IKeyValue {
    key: string,
    value: number,
}

interface IProps {
    user: string,
    session: string,
    contributions?: InputModel[],
    ticket?: TicketModel,
    type: "locked" | "board" | "points" | "slider",
    vote?: VoteModel,
    board?: BoardModel,
    options?: OptionModel[],
    stack?: GroupModel,
}

interface IState {
    user: string,
    session: string,
    title: string,
    description: string,
    total: number,
    ratings: IKeyValue[],
}


export class ParticipateView extends React.Component<IProps, IState> {
    constructor( props: IProps | Readonly<IProps> ) {
        super( props );
        this.state = {
            session: this.props.session,
            user: this.props.user,
            title: "",
            description: "",
            total: 0,
            ratings: [ ]

        }

    }


    handleChange = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)  => {
        const NAME = e.currentTarget.name;
        const VALUE = e.currentTarget.value;

        if ( NAME === "title" ) {
            this.setState( { title: VALUE } );
        }else if ( NAME === "description" ) {
            this.setState( { description: VALUE } );
        }
    }



    totalPoints = (ratings: IKeyValue[])  => {
        const RATINGS = ratings;
        let Total = 0;

        for (let I = 0; I < RATINGS.length; I++)  {
            Total += RATINGS[I] . value;
        }

        this.setState({  total: Total });
    }



    handleRatings = (value: number, key: string) => {
        if (!this.props.vote || !this.props.options) {
            return;
        }

        const RATING: IKeyValue = {
            value: value,
            key: key
        };

        let Ratings = this.state.ratings;
        if (Ratings.length !== this.props.options.length) {
            console.log(Ratings);
            Ratings = [];
            for (let I = 0; I < this.props.options.length; I++) {
                const OPTION: IKeyValue = {
                    value: this.props.vote.type === "slider"
                    ? this.props.vote.lowNumber
                                  : 0,
                    key: this.props.options[I].id
                };
                Ratings.push(OPTION);
            }
            console.log(Ratings);
        }

        const INDEX = Ratings.findIndex(x => x.key === RATING.key);

        if (INDEX !== -1) {

            if (this.props.vote.type === "points") {
                if (Ratings[INDEX].value === RATING.value) {
                    RATING.value = 0;
                }
                else if ((this.state.total - Ratings[INDEX].value + RATING.value) > this.props.vote.highNumber) {
                    RATING.value = this.props.vote.highNumber - this.state.total + Ratings[INDEX].value;
                }
            }


            Ratings[INDEX] =  RATING;
        }
        else {
            Ratings = [];
            for (let  I = 0; I < this.props.options.length; I++) {
                if (this.props.options[I]. id !== key) {
                    const OPTION: IKeyValue = { value: this.props.vote.lowNumber, key: this.props.options[I].id };

                    Ratings.push(OPTION);
                }
                else {
                    Ratings.push(RATING);
                }
            }
        }

        if (this.props.vote.type === "points") {
            this.totalPoints(Ratings);
        }

        this.setState({
             ratings: Ratings
        });
    }

    commit = {
        input: async ( event: React.MouseEvent<HTMLButtonElement | MouseEvent> ) => {
            event.preventDefault();
            event.stopPropagation();
            const BOARD = this.props.board;
            if ( BOARD && this.props.stack) {
                let Title = this.state.title;
                let Description = this.state.description;

                if ( !Title && Description ) {
                    Title = Description;
                }
                else if (!Description && Title) {
                    Description = Title;
                }
                await InputController.create( this.state.session, BOARD.phaseId, BOARD.id, this.props.stack.id, Title, Description, this.state.user );
            }
        },
        ticket: async (event: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
            event.preventDefault();
            event.stopPropagation();

            const VOTE = this.props.vote;
            if (VOTE  && !this.props.ticket) {
                const Ratings = this.state.ratings;

                await TicketController.create(this.state.session, this.state.session, VOTE.boardId, VOTE.id, Ratings, this.state.user);
            }
        }
    }

    PointsRender(maxNumber: number) {
        const NUMBER_ARRAY: number[] = [ ];
        for ( let I = 1; I <= maxNumber; I++ ) {
            NUMBER_ARRAY.push( I );
        }
        return NUMBER_ARRAY;
    }


    typeRenderer = {
        board: ( ) => {
            return (
                <div>
                    <div>

                        <Typography
                            variant="h3">
                            Add Input!
                        </Typography>


                        <form>
                            <Box sx={{position: "relative"}}>
                                <Box sx={{display: "flex", flexDirection: "column", position: "relative" }}>
                                <TextField
                                    InputLabelProps={ { required: false } }
                                    name="title"
                                    onChange={this.handleChange}
                                    placeholder="Title"
                                    required={ this.state.description.length > 35 }
                                    type="text"
                                        variant="standard"
                                    value={ this.state.title } />

                                <TextField
                                    InputLabelProps={ { required: false } }
                                    multiline
                                    name="description"
                                    onChange={this.handleChange}
                                    placeholder="Description"
                                    required
                                    rows={ 4}
                                    type="text"
                                    value={ this.state.description }
                                    variant="filled" />
                                    </Box>
                                <Box sx={{position: "absolute", left: "50%", bottom: "0", transform: "translate(-50%, 50%)"}}>

                                    <Button
                                        onClick={this.commit.input}>
                                        Add Input
                                    </Button>
                                </Box>

                            </Box>
                        </form>

                        <Divider />

                        <div>

                            <Typography
                                variant="overline">
                                Your Contributions
                            </Typography>

                            { this.props.contributions
                                && this.props.contributions.map( con => <InputCard
                                                                            input={con} /> ) }
                        </div>
                    </div>
                </div>
            );
        },
        closed: ( ) => {
            const OPTIONS = this.props.options;
            if ( OPTIONS )
                OPTIONS.sort( ( a, b ) => a.title.localeCompare( b.title ) );
            return (
                <div>

                    <div>

                        <Typography
                            variant="h3">
                            Input closed!
                        </Typography>

                        <Divider />

                        <div>

                            <Typography>

                                Your Contributions
                            </Typography>

                            {
                                this.props.contributions
                                    && this.props.contributions.map( con => <InputCard
                                                                                input={con} /> )
                            }
                        </div>
                    </div>
                </div>
            );
        },
        points: () => {
            const OPTIONS = this.props.options;
            if (OPTIONS && this.props.vote) {
                OPTIONS.sort((a, b) => a.title.localeCompare(b.title));
                return (
                    <div
                        style={{ position: "relative", top: "0", left: "0", height: "100%", width: "100%", background: "#eaeaea", padding: "40px 20px 20px 20px" }}>
                        <form>
                            <Typography
                                sx={{ position: "relative", left: "50%", transform: "translateX(-50%)", textAlign: "center" }}
                                variant="h4">
                                {this.props.vote.title}
                            </Typography>

                            <div
                                style={{ width: "90%", height: "1px", position: "relative", left: "5%", background: Theme.palette.background.paper, marginTop: "5px", marginBottom: "15px" }} />

                            {
                                this.props.vote.title
                                    && <Typography
                                           sx={{ position: "relative", left: "50%", transform: "translateX(-50%)", textAlign: "center", marginBottom: "10px" }}
                                           variant="body1">
                                           {this.props.vote.description}
                                       </Typography>
                            }

                            <div
                                style={{ width: "90%", height: "1px", position: "relative", left: "5%", background: Theme.palette.background.paper, marginTop: "5px", marginBottom: "15px" }} />

                            <Typography
                                sx={{ position: "relative", left: "50%", transform: "translateX(-50%)", textAlign: "center", marginBottom: "10px" }}
                                variant="subtitle2">
                                {this.props.vote.highNumber - this.state.total} points
                            </Typography>

                            <div
                                style={{ background: Theme.palette.background.paper, display: "flex", flexDirection: "column", gap: "2px", padding: "2px 0" }}>

                                {OPTIONS
                                    && OPTIONS.map((option) => //TODO: Export Option to own component && Does the Slider Need that too?
                                        <div
                                            style={{ padding: "10px", background: "#eaeaea", display: "flex", flexDirection: "column", gap: "5px" }}
                                            key={option.id}>

                                            <Typography
                                                variant="subtitle2">

                                                {option.title}
                                            </Typography>

                                            <div
                                                style={{
                                                boxSizing: "border-box",
                                                overflow: "hidden",
                                                display: "flex",
                                                height: "35px",
                                                width: "100%",
                                                position: "relative",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                borderRadius: "1rem",
                                                border: `2px solid ${Theme.palette.text.primary}`
                                            }}>
                                                { this.props.vote
                                                    && this.PointsRender(this.props.vote.lowNumber).map(point =>
                                                        <Box
                                                            sx={{
                                                                boxSizing: "border-box",
                                                                borderRadius: point === 1
                                                                                  ? "1rem 0 0 1rem"
                                                                                  : point === this.props.vote.lowNumber
                                                                                    ? "0 1rem 1rem 0"
                                                                                    : "0",
                                                                height: "100%",
                                                                flexGrow: 1,
                                                                lineHeight: "29px",
                                                                textAlign: "center",
                                                                transition: "all linear 0.25s",
                                                                border: `1px solid ${this.state.ratings.find(x => x.key === option.id)?.value === point
                                                                            ? Theme.palette.background.paper
                                                                            : "#eaeaea"}`,
                                                                fontFamily: "Lato, Roboto, Arial",
                                                                fontSize: "1rem",
                                                                color: Theme.palette.text.primary,
                                                                background: this.state.ratings.find(x => x.key === option.id)?.value === point ? option.color : Theme.palette.background.default,
                                                                ":hover": {
                                                                    background: Theme.palette.background.paper,
                                                                    lineHeight: "27px",
                                                                    color: option.color,
                                                                    border: `2px solid ${option.color}`,
                                                                    boxShadow: "0 0 100px 100px rgba(0, 0, 0, 0.1) inset"
                                                                }
                                                            }}
                                                            active={this.state.ratings.find(x => x.key === option.id)?.value === point}
                                                            key={`${option.id}-${point}`}
                                                            onClick={() => this.handleRatings(point, option.id)}>

                                                            {point}
                                                        </Box>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    )
                                }

                            </div>
                            <div
                                style={{ padding: "10px", position: "relative", left: "50%", transform: "translateX(-50%)", width: "150px" }}>
                                <Button
                                    disabled={this.props.vote.highNumber - this.state.total !== 0}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => this.commit.ticket(e)}>
                                    Send Vote
                                </Button>
                            </div>
                        </form>
                    </div>
                );
            }
        }, slider: () => {
                const Options = this.props.options;
                const Vote = this.props.vote;
                if (!Options || !Vote)
                    return;
                Options.sort((a, b) => a.title.localeCompare( b.title ) );
                return (
                    <div
                        style={{ position: "relative", top: "0", left: "0", height: "100%", width: "100%", background: "#eaeaea", padding: "40px 20px 20px 20px" }}>
                        <form>
                            <Typography
                                sx={{ position: "relative", left: "50%", transform: "translateX(-50%)", textAlign: "center" }}
                                variant="h4">
                                {Vote.title}
                            </Typography>

                            <div
                                style={{ width: "90%", height: "1px", position: "relative", left: "5%", background: Theme.palette.background.paper, marginTop: "5px", marginBottom: "15px" }} />

                            {
                                Vote.title
                                    && <Typography
                                           sx={{ position: "relative", left: "50%", transform: "translateX(-50%)", textAlign: "center", marginBottom: "10px" }}
                                           variant="body1">
                                           {Vote.description}
                                       </Typography>
                            }

                            <div
                                style={{ width: "90%", height: "1px", position: "relative", left: "5%", background: Theme.palette.background.paper, marginTop: "5px", marginBottom: "15px" }} />

                            <div
                                style={{ background: Theme.palette.background.paper, display: "flex", flexDirection: "column", gap: "2px", padding: "2px 0" }}>

                                { Vote &&
                                    Options
                                        && Options.map( (option) => //TODO: Export Option to own component && Does the Slider Need that too?
                                            <div
                                                style={{ padding: "10px", background: "#eaeaea", display: "flex", flexDirection: "column", gap: "5px" }}
                                                key={option.id}>

                                                <Box sx={{position: "relative", padding: "0 5px"}}>
                                                    <Typography variant="subtitle2" sx={{display: "inline-block", height: "30px", lineHeight: "30px"}}>
                                                        {option.title}
                                                    </Typography>

                                                    <Typography variant="body2" sx={{display: "inline-block", position: "relative", height: "30px", lineHeight: "30px", textAlign: "center", left: "50%", transform: "translateX(-50%)"}}>
                                                        {this.state.ratings.find(x => x.key === option.id) ? this.state.ratings.find(x => x.key === option.id)?.value : Vote.lowNumber  }
                                                    </Typography>
                                                </Box>

                                                <div style={{padding: "0 15px"}}>

                                                    <Slider
                                                        defaultValue={Vote.lowNumber}
                                                        id={option.id}
                                                        marks={true}
                                                        sx={{color: option.color}}
                                                        max={Vote.highNumber}
                                                        min={Vote.lowNumber}
                                                        onChange={(event: Event, value: number | number[]) => this.handleRatings( Array.isArray(value) ? value[0] : value, option.id )}
                                                        step={1}
                                                        track={false} />
                                                </div>
                                            </div>
                                        )
                                }

                            </div>
                            <div
                                style={{ padding: "10px", position: "relative", left: "50%", transform: "translateX(-50%)", width: "150px" }}>
                                <Button
                                    onClick={(e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => this.commit.ticket(e)}>
                                    Send Vote
                                </Button>
                            </div>
                        </form>
                    </div>
                );
            },
    }


    render() {

        if (this.props.type  === "board" && this.props.board) {
            return this.typeRenderer.board();

        } else if (this.props.type === "points" && !this.props.ticket) {
            return this.typeRenderer.points();
        } else if (this.props.type === "slider" && !this.props.ticket) {
            return this.typeRenderer.slider();
         }
        else if (this.props.type === "locked") {
             return this.typeRenderer.closed();
        } else {
            return "What should be here?";
        }
    }

}