import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles';

//declare module '@mui/material/styles' {
//  interface Theme {
//    palette: {
//      type: string,
//      primary: {
//        main: string,
//      },
//      secondary: {
//        main: string,
//      },
//      text: {
//        primary: string,
//        secondary: string,
//        disabled: string,
//        hint: string,
//      },
//      error: {
//        main: string,
//      },
//      success: {
//        main: string,
//      },
//      warning: {
//        main: string,
//      },
//    },
//    typography: {
//      h1: {
//        fontSize: string,
//        fontWeight: 500,
//      },
//      h2: {
//        fontSize: string,
//        fontWeight: number,
//      },
//      h3: {
//        fontSize: string,
//        fontWeight: number,
//      },
//      h4: {
//        fontSize: string,
//        fontWeight: number,
//      },
//      body1: {
//        lineHeight: number,
//        fontSize: string,
//        fontWeight: number,
//      },
//      body2: {
//        lineHeight: number,
//        fontSize: string,
//        fontWeight: number,
//      },
//      subtitle1: {
//        fontSize: string,
//        lineHeight: number,
//        fontWeight: number,
//      },
//      overline: {
//        lineHeight: number,
//        fontSize: string,
//      },
//      button: {
//        fontSize: string,
//        lineHeight: number,
//      },
//      fontSize: string,
//      caption: {
//        fontSize: string,
//        lineHeight: number,
//      },
//    },
//    props: {
//      MuiTooltip: {
//        arrow: boolean,
//      },
//    },
//    shape: {
//      borderRadius: number,
//    },
//    overrides: {
//      MuiSwitch: {
//        root: {
//          width: number,
//          height: number,
//          padding: number,
//          margin: number,
//        },
//        switchBase: {
//          padding: number,
//          '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
//            transform: string,
//            color: string,
//            '& + $track': {
//              opacity: number,
//              border: string,
//            },
//          },
//        },
//        thumb: {
//          width: number,
//          height: number,
//        },
//        track: {
//          borderRadius: number,
//          border: string,
//          backgroundColor: string,
//          opacity: number,
//          transition: string,
//        },
//      },
//    }
//  }

//  // allow configuration using `createTheme`
//  interface ThemeOptions {
//    palette?: {
//      type?: string,
//      primary?: {
//        main?: string,
//      },
//      secondary?: {
//        main?: string,
//      },
//      text?: {
//        primary?: string,
//        secondary?: string,
//        disabled?: string,
//        hint?: string,
//      },
//      error?: {
//        main?: string,
//      },
//      success?: {
//        main?: string,
//      },
//      warning?: {
//        main?: string,
//      },
//    },
//    typography?: {
//      h1?: {
//        fontSize?: string,
//        fontWeight?: 500,
//      },
//      h2?: {
//        fontSize?: string,
//        fontWeight?: number,
//      },
//      h3?: {
//        fontSize?: string,
//        fontWeight?: number,
//      },
//      h4?: {
//        fontSize?: string,
//        fontWeight?: number,
//      },
//      body1: {
//        lineHeight?: number,
//        fontSize?: string,
//        fontWeight?: number,
//      },
//      body2?: {
//        lineHeight?: number,
//        fontSize?: string,
//        fontWeight?: number,
//      },
//      subtitle1?: {
//        fontSize?: string,
//        lineHeight?: number,
//        fontWeight?: number,
//      },
//      overline?: {
//        lineHeight?: number,
//        fontSize?: string,
//      },
//      button?: {
//        fontSize?: string,
//        lineHeight?: number,
//      },
//      fontSize?: string,
//      caption?: {
//        fontSize?: string,
//        lineHeight?: number,
//      },
//    },
//    props?: {
//      MuiTooltip?: {
//        arrow?: boolean,
//      },
//    },
//    shape?: {
//      borderRadius?: number,
//    },
//    overrides?: {
//      MuiSwitch?: {
//        root?: {
//          width?: number,
//          height?: number,
//          padding?: number,
//          margin?: number,
//        },
//        switchBase?: {
//          padding?: number,
//          '&$checked, &$colorPrimary$checked, &$colorSecondary$checked'?: {
//            transform?: string,
//            color?: string,
//            '& + $track'?: {
//              opacity?: number,
//              border?: string,
//            },
//          },
//        },
//        thumb?: {
//          width?: number,
//          height?: number,
//        },
//        track?: {
//          borderRadius?: number,
//          border?: string,
//          backgroundColor?: string,
//          opacity?: number,
//          transition?: string,
//        },
//      };
//    }
//  }
//}

const Theme = {
  palette: {
    type: 'light',
    primary: {
      main: '#0095b3',
    },
    secondary: {
      main: '#66BBCC',
    },
    text: {
      primary: '#292F40',
      //secondary: '#393E4D',
      disabled: '#a4a5a9',
      secondary: '#6A6D7A', //hint
    },
    error: {
      main: '#FF0000',
    },
    success: {
      main: '#3DD75D',
    },
    warning: {
      main: '#f57f17',
    },
    background: {
      default: '#fafafa',
    },
  },
  typography: {
    fontFamily: "Lato, Roboto, Arial",
    h1: {
      fontSize: '3.7rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    body1: {
      lineHeight: 1.25,
      fontSize: '1rem',
      fontWeight: 500,
    },
    body2: {
      lineHeight: 1.3,
      fontSize: '0.75rem',
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: '0.75rem',
      lineHeight: 0.97,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: 1.25,
      fontWeight: 700,
    },
    overline: {
      lineHeight: 2,
      fontSize: '1rem',
    },
    button: {
      fontSize: '1rem',
      lineHeight: 1,
    },
    fontSize: '1rem',
    caption: {
      fontSize: '1rem',
      lineHeight: 1,
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
} as const;

type CustomTheme = {
  [Key in keyof typeof Theme]: typeof Theme[Key]
}
declare module '@mui/material/styles/createTheme' {
  interface Theme extends CustomTheme { }
  interface ThemeOptions extends CustomTheme { }
}

export default createTheme((Theme) as unknown as ThemeOptions);