import React from "react";
import Axios from "axios";
import {BoardRoutes} from "./Routes";
import { BoardModel } from "../Models/BoardModel";


export const BoardController = {
    async create(ownerId:string, sessionId:string, phaseId:string, title:string, description:string) {
        const DATA = {
            Title: title,
            Description: description,
            UserId: ownerId
        };
        let Result: BoardModel | undefined;

        await Axios.post(BoardRoutes.create(sessionId, phaseId), DATA).then(res => {
            if (res.status === 201) {
                Result = res.data;
            }
        }, err => {
            if (err.status === 400) {
                alert(err.error);
            }
        });

        return Result;
    },


    async delete(sessionId:string, phaseId:string, boardId:string) {
        let Result: boolean = false;

        await Axios.delete(BoardRoutes.delete(sessionId, phaseId, boardId)).then(res => {
            if (res.status === 204) {
                Result = true;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },


    timer: {
        set: async (sessionId:string, phaseId:string, boardId:string, seconds:number) => {
            const DATA = {
                TimeInSeconds: seconds
            };

            let Result: BoardModel|null = null;
            await Axios.patch(BoardRoutes.SetTimer(sessionId, phaseId, boardId), DATA).then(res => {
                if (res.status === 200) {
                    Result = res.data;
                }
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        start: async (sessionId:string, phaseId:string, boardId:string) => {
            let Result: BoardModel | null = null;
            await Axios.post(BoardRoutes.StartTimer(sessionId, phaseId, boardId)).then(res => {
                if (res.status === 200) {
                    Result = res.data;
                }
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    },


    async move(sessionId:string, phaseId:string, boardId:string, targetIndex:number) {
        const DATA = {
            TargetIndex: targetIndex
        };
        let Result: Array<BoardModel> = [];

        await Axios.patch(BoardRoutes.move(sessionId, phaseId, boardId), DATA).then(res => {
            if (res.status === 200) {
                Result = res.data;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },


    get: {
        byPhase: async (sessionId:string, phaseId:string) => {
            let Result: Array<BoardModel> = [];

            await Axios.get(BoardRoutes.getByPhase(sessionId, phaseId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        bySession: async ( sessionId: string ) => {
            let Result: Array<BoardModel> = [];

            await Axios.get(BoardRoutes.getByAll(sessionId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        byId: async (sessionId:string, phaseId:string, boardId:string) => {
            let Result: BoardModel | null = null;

            await Axios.get(BoardRoutes.getById(sessionId, phaseId, boardId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    }
};