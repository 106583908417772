import React from "react";
import Axios from "axios";
import {TicketRoutes} from "./Routes";
import {TicketModel} from "../Models/TicketModel"


export const TicketController = {
    async create(sessionId: string, phaseId: string, boardId: string, voteId: string, ratings: { key: string, value: number }[], userId: string) {
        const DATA = {
            UserId: userId,
            Ratings: ratings
        };
        let Result: TicketModel | null = null;

        await Axios.post( TicketRoutes.add( sessionId, phaseId, boardId, voteId ), DATA ).then( res => {
            if ( res.status === 201 ) {
                Result = res.data;
            }
        }, err => {
            if ( err.status === 400 ) {
                alert( err.error );
            }
        } );

        return Result;
    },


    async delete(sessionId: string, phaseId: string, boardId: string, voteId: string, ticketId: string) {
        let Result: boolean = false;

        await Axios.delete( TicketRoutes.remove( sessionId, phaseId, boardId, voteId, ticketId ) ).then( res => {
            if ( res.status === 204 ) {
                Result = true;
            }
        }, err => {
            alert( err.error );
        } );

        return Result;
    },


    async update(sessionId: string, phaseId: string, boardId: string, voteId: string, ticketId: string, ratings: [{ key: string, value: number }], userId: string) {
        const DATA = {
            UserId: userId,
            Ratings: ratings
        };
        let Result: TicketModel | null = null;

        await Axios.put( TicketRoutes.update( sessionId, phaseId, boardId, voteId, ticketId ), DATA ).then( res => {
            if ( res.status === 200 ) {
                Result = res.data;
            }
        }, err => {
            alert( err.error );
        } );

        return Result;
    },


    get: {
        byVote: async ( sessionId: string, phaseId: string, boardId: string, voteId: string ) => {
            let Result: Array<TicketModel> = [ ];

            await Axios.get( TicketRoutes.getAllInVote( sessionId, phaseId, boardId, voteId ) ).then( res => {
                Result = res.data;
            }, err => {
                alert( err.error );
            } );

            return Result;
        },
        bySession: async ( sessionId: string ) => {
            let Result: Array<TicketModel> = [ ];
            await Axios.get( TicketRoutes.getAll( sessionId ) ).then( res => {
                Result = res.data;
            }, err => {
                alert( err.error );
            } );

            return Result;
        },
        byId: async ( sessionId: string, phaseId: string, boardId: string, voteId: string, ticketId:string) => {
            let Result: TicketModel | null = null;

            await Axios.get(TicketRoutes.getById(sessionId, phaseId, boardId, voteId, ticketId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    }
};