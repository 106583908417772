import React, {Component} from "react";
import {Typography, IconButton, Menu, MenuItem, Popover, TextField, PopoverOrigin} from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { BlockPicker } from "react-color";

export interface IMenuItem {
    text: string,
    callback: (() => void) | null,
}

interface IProps {
    anchor: Element | null,
    onClose: (arg0: Event) => void,
    anchorOrigin?: PopoverOrigin,
    transformOrigin?: PopoverOrigin,
    items: Array<IMenuItem>,
}

export class MenuView extends Component<IProps> {
   render( ) {
        return(
            <Menu
                anchorEl={this.props.anchor}
                anchorOrigin={this.props.anchorOrigin ? this.props.anchorOrigin : {
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                id="simple-menu"
                keepMounted
                onClose={this.props.onClose}
                open={Boolean( this.props.anchor )}
                transformOrigin={this.props.transformOrigin ? this.props.transformOrigin : {
                                    vertical: "top",
                                    horizontal: "right"
                                }}>
                {this.props.items.map((item) => <MenuItem key={item.text + "-menu"} onClick={item.callback ? item.callback : undefined}>{item.text}</MenuItem>)}
            </Menu>
        );
    }
}