import * as React from "react";
import { Typography, IconButton, Menu, MenuItem, Popover, TextField, Box } from "@mui/material";
import { Draggable, Droppable, DraggableProvided, DroppableProvided } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { ColorChangeHandler, ColorResult } from "react-color";
import { useTheme } from '@mui/material/styles';
import { styled } from "@mui/material/styles"
import { MenuView, IMenuItem } from "../General/Menu";
import { ColorBlock } from "../General/ColorBlock";
import { TypoField } from "../General/TypoField";
import Theme from "../../../Theme/ThemeOptions";
import { GroupController } from "../../../Controllers/GroupController";

interface IProps {
    color: string,
    column: number,
    id: string,
    index: number,
    isCollapsed: boolean,
    name: string,
    sessionId: string,
    phaseId: string,
    boardId: string,
}

interface IState {
    color: string,
    column: number,
    id: string,
    index: number,
    isCollapsed: boolean,
    name: string,
    anchors: {
        color: Element | null,
        menu: Element | null,
    },
}


interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    selected?: boolean,
    color: string,
    innerRef: (element: HTMLElement | null) => any,
}
const GroupContainer = styled(({ innerRef, ...divProps }: IDiv) => (
    <div ref={innerRef} {...divProps} />
), { shouldForwardProp: (prop) => prop !== "selected" && prop !== "color" })<IDiv>(({ selected, color }) => ({
    borderRadius: 20,
    position: "relative",
    boxSizing: "border-box",
    width: "350px",
    verticalAlign: "top",
    background: color,
    marginBottom: "15px",
    transition: "background 0.5s",
    ...(selected ? { padding: 12, border: `8px solid${Theme.palette.text.primary}` } : { padding: 20, border: `0px solid${Theme.palette.text.primary}` })
}));

export  class DraggableGroup extends React.Component<IProps, IState> {
    constructor(props: IProps | Readonly<IProps>) {
        super(props);
        this.state = {
            color: props.color,
            column: props.column,
            id: props.id,
            index: props.index,
            isCollapsed: props.isCollapsed,
            name: props.name,
            anchors: {
                color: null,
                menu: null,
            }
        };
    }



    OpenColor = () => {
        const Anchor = this.state.anchors.menu;

        this.setState( {
            anchors: {
                menu: null,
                color: Anchor
            }
        } );
    }

    ChangeColor: ColorChangeHandler = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const Color = color.hex;

        this.setState({color: Color});

    }

    ChangeColorComplete: ColorChangeHandler = async (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();

        const Color = color.hex;
        this.CloseColor();
        await GroupController.setColor(this.props.sessionId, this.props.phaseId, this.props.boardId, this.props.id, Color);

    }

    GroupCollapse = () => {
        GroupController.collapse.single(this.props.sessionId, this.props.phaseId, this.props.boardId, this.props.id);
    }

    OpenMenu(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.stopPropagation();
        const Anchor = event.currentTarget;

        this.setState( {
            anchors: {
                menu: Anchor,
                color: null,
            }
        } );
    }


    CloseMenu =( ) => {
        this.setState( {
            anchors: {
                ...this.state.anchors,
                menu: null,
            }
        } );
    }

    CloseColor = () => {
        this.setState( {
            anchors: {
                ...this.state.anchors,
                color: null,
            }
        } );
    }

    HandleName(name: string) {

        //TODO: Write this method
        GroupController.rename(this.props.sessionId, this.props.phaseId, this.props.boardId, this.props.id, name);
    }

    GroupMenu: Array<IMenuItem> = [
        { text: "Rename", callback: null },
        { text: "Duplicate", callback: null },
        { text: "Color", callback: this.OpenColor },
        { text: "Delete", callback: null }
    ];

    render() {
        //const SELECTED = this.props.data.selected && this.props.data.selected.groups && this.props.data.selected.groups.findIndex(x => x.group === this.props.data.id) !== -1;
        return (
            <Draggable
                draggableId={this.props.id}
                index={this.props.index}
                key={this.props.id}>
                {(provided: DraggableProvided) => (
                    <GroupContainer color={Boolean(this.state.anchors.color) ? this.state.color : this.props.color} selected={false} id={this.props.id}
                        innerRef={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div>
                            <Droppable
                                droppableId={this.props.id}
                                type="input">
                                {(provided: DroppableProvided) => (
                                    <div
                                        ref={provided.innerRef} {...provided.droppableProps}
                                        style={{ minHeight: "40px" }}>
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <TypoField variant="subtitle1" text={this.props.name} submitChange={this.HandleName} />

                                            <IconButton onClick={(e) =>this.OpenMenu(e)}>
                                            <span className="material-icons-outlined">menu</span>
                                            </IconButton>
                                        </Box>
                                        <Box sx={{display:"flex", flexDirection: "column", gap: "10px", marginBottom: "10px"}}>
                                        {!this.props.isCollapsed
                                            && this.props.children}
                                        </Box>
                                        {provided.placeholder}

                                        <Typography variant="subtitle1">
                                            {this.props.children && React.Children.count(this.props.children) > 0
                                                ? `${React.Children.count(this.props.children)} INPUT`
                                                : "NO INPUT"}
                                        </Typography>

                                        <IconButton onClick={() => this.GroupCollapse()} sx={{ transition: "all 0.5s", position: "absolute", bottom: "0", left: "50%", transform: "translate(-50%, 40%)", background: Boolean(this.state.anchors.color) ? this.state.color : this.props.color, ":hover": {background: this.props.color, boxShadow: "0 0 30px 30px rgba(0, 0, 0, 0.1) inset"} }}>
                                            {
                                                this.props.isCollapsed
                                                    ? <span className="material-icons-outlined" style={{fontSize: "18px"}}>expand_more</span>
                                                    : <span className="material-icons-outlined" style={{fontSize: "18px"}}>expand_less</span>
                                            }
                                        </IconButton>

                                    </div>
                                )}

                            </Droppable>
                        </div>
                        <MenuView items={this.GroupMenu} anchor={this.state.anchors.menu} onClose={this.CloseMenu} />
                        <ColorBlock anchor={this.state.anchors.color} onClose={this.CloseColor} onChange={this.ChangeColor} onChangeComplete={this.ChangeColorComplete} color={this.state.color ? this.state.color : this.props.color} />
                    </GroupContainer>
                )}
            </Draggable>
        );
    }
}