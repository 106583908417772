import * as React from "react";
import { Typography } from "@mui/material";
import "material-icons/iconfont/material-icons.css";
import { styled } from "@mui/material/styles";
import { OptionModel } from "../../../Models/OptionModel";
import { TicketModel } from "../../../Models/TicketModel";
import { OptionSlider } from "./OptionSlider";
import { Background } from "./Background";



interface IProps {
    countdownEnabled: boolean,
    countdownEnd: number,
    countdownTime: number,
    countdownLock: boolean,
    lockData: boolean,
    description: string,
    id: string,
    index: number,
    highDescription: string,
    highNumber: number,
    lowDescription: string,
    lowNumber: number,
    hideResults: boolean,
    boardId: string,
    title: string,
    type: string,
    options: Array<OptionModel>,
    tickets: Array<TicketModel>,
}

interface IState { }


interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}

const Container = styled( ( { active, ...divProps }: IDiv ) => (
    <div {...divProps} />
), { shouldForwardProp: ( prop ) => prop !== "active" } )<IDiv>( ( { active } ) => ( {
    width: "100%",
    height: "90%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignContent: "center"
} ) );

const Results = styled( ( { active, ...divProps }: IDiv ) => (
    <div {...divProps} />
), { shouldForwardProp: ( prop ) => prop !== "active" } )<IDiv>( ( { active } ) => ( {
    width: "50%",
    position: "relative",
    flexGrow: 1,
    display: "flex",
    left: "50%",
    paddingLeft: "20px",
    transform: "translateX(-50%)",
    flexDirection: "column",
    gap: "20px"
} ) );

export class SliderVote extends React.Component<IProps, IState> {


    render( ) {
        const OPTIONS = this.props.options;
        OPTIONS.sort((a, b) => b.averageRating - a.averageRating);
        return(
            <Container>

                <Typography sx={{position: "relative", margin: "20px", left: "50%", transform: "translateX(-50%)", textAlign: "center"}}
                    variant="h2">
                    {this.props.title}
                </Typography>

                <Typography sx={{position: "relative", margin: "20px", left: "50%", transform: "translateX(-50%)", textAlign: "center"}}
                    variant="h4">
                    {this.props.description}
                </Typography>

                <Results>
                    {
                        OPTIONS.map( ( option ) =>
                            <OptionSlider lowNumber={this.props.lowNumber} highNumber={this.props.highNumber} {...option}>
                            </OptionSlider>
                        )
                    }
                </Results>
            </Container>
        );
    }
}