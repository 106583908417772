import * as React from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import { Typography, Popper, Paper, Box, } from "@mui/material";

import { styled } from "@mui/material/styles"

import 'material-icons/iconfont/material-icons.css';
import Theme from "../../../Theme/ThemeOptions";


interface IProps {
    id: string,
    index: number,
    dragType: "input" | "group",
}

interface IState {
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}

const ColumnContainer = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    width: "351px",
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0 10px",
    borderRight: `1px solid ${Theme.palette.background.paper}`,
    pointerEvents: "auto"
}));

export class ColumnView extends React.Component<IProps, IState> {
    render() {
        return (
            <ColumnContainer
                id={this.props.id}>

                <Droppable
                    droppableId={`Column-${this.props.index}`} type="group">
                    {(provided: DroppableProvided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ minHeight: this.props.dragType === "group" ? "95%" : "0%" }}>
                            {this.props.children}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                <Droppable
                    droppableId={`Col-${this.props.index}`} type="input">
                    {(provided: DroppableProvided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ minHeight: "5%", flexGrow: 1 }}>

                        </div>
                    )}
                </Droppable>

            </ColumnContainer>
        );
    }
}