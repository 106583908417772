import * as React from "react";
import { Typography, IconButton, Menu, MenuItem, Popover, TextField, PopoverOrigin } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import 'material-icons/iconfont/material-icons.css';
import { BlockPicker, ColorChangeHandler } from "react-color";
import { styled, alpha } from "@mui/material/styles";
import { MenuView, IMenuItem } from "../General/Menu";
import Theme from "../../../Theme/ThemeOptions";


const BoardMenu: Array<IMenuItem> = [
    { text: "Rename", callback: null },
    { text: "Delete", callback: null }
];

interface IProps {
    index: number,
    id: string,
    name: string,
    size: number,
}

interface IState {
    hover: boolean,
    collapsed: boolean,
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    active?: boolean,
}

const Container = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" })<IDiv>(({ active }) => ({
    width: "100%",
    borderTop: `4px solid ${Theme.palette.text.secondary}`,
    borderBottom:  `4px solid ${Theme.palette.text.secondary}`,
}));

const CardHeader = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active"  })<IDiv>(({ active}) => ({
    width: "100%",
    position: "relative",
    display: "flex",
    background: Theme.palette.background.paper,
    border: active
        ? `1px solid ${Theme.palette.primary.main}`
        : "",
    '&:hover': {
        background: Theme.palette.background.default,
    }
}));

const CardBody = styled(({ active, ...divProps }: IDiv) => (
    <div {...divProps} />
), { shouldForwardProp: (prop) => prop !== "active" && prop !== "theme"  })<IDiv>(({ active, theme }) => ({
    width: "100%",
    position: "relative",
}));

export class PhaseCard extends React.Component<IProps, IState> {
    state = {
        hover: false,
        collapsed: false,
    }



    //TODO: Draggable cards?
    render() {
        return (
            <Container  {...this.props}>
                {this.props.children}
            </Container>
        );
    }
}