import React from "react";
import Axios from "axios";
import {SessionRoutes} from "./Routes";
import { SessionModel } from "../Models/SessionModel";


export const SessionController = {
    async create(title:string, userId:string) {
        const Data = {
            Name: title,
            OwnerId: userId
        };
        let result: SessionModel | null = null;

        await Axios.post(SessionRoutes.create(), Data).then(res => {
            if (res.status === 201) {
                result = res.data;
            }
        }, err => {
            if (err.status === 400) {
                alert(err.error);
            }
        });

        return result;
    },


    async delete(ownerId:string, sessionId:string) {
        let result: boolean = false;

        await Axios.delete(SessionRoutes.delete(ownerId, sessionId)).then(res => {
            if (res.status === 204) {
                result = true;
            }
        }, err => {
            alert(err.error);
        });

        return result;
    },

    async active(sessionId:string, phaseId:string, boardId:string) {
        let result: boolean = false;

        await Axios.patch(SessionRoutes.SetActiveBoard(sessionId, phaseId, boardId)).then(res => {
            if (res.status === 200) {
                result = true;
            }
        }, err => {
            alert(err.error);
        });

        return result;
    },


    async rename(ownerId:string, sessionId:string, title:string) {
        const Data = {
            Name: title
        };

        let result: SessionModel | null = null;

        await Axios.patch(SessionRoutes.rename(ownerId, sessionId), Data).then(res => {
            result = res.data;
        }, err => {
            alert(err.error);
        });

        return result;
    },

    connect: async (sessionId:string): Promise<SessionModel | null> => {
        let result: SessionModel | null = null;
        await Axios.get(SessionRoutes.connect(sessionId)).then(res => {
            result = res.data;
        }, err => {
            alert(err.error);
        });
        return result;
    },

    get: {
        owned: async (ownerId:string) => {
            let result: Array<SessionModel> = [];

            await Axios.get(SessionRoutes.getOwned(ownerId)).then(res => {
                result = res.data;
            }, err => {
                alert(err.error);
            });
            return result;
        },
        byId: async (ownerId:string, sessionId:string) => {
            let result: SessionModel | null = null;

            await Axios.get(SessionRoutes.getById(ownerId, sessionId)).then(res => {
                result = res.data;
            }, err => {
                alert(err.error);
            });

            return result;
        }
    }
};