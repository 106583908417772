import React from "react";
import Axios from "axios";
import { GroupRoutes } from "./Routes";
import { GroupModel } from "../Models/GroupModel";

export const GroupController = {
    async create(sessionId: string, phaseId: string, boardId: string, name: string, column: number) {
        const DATA = {
            Name: name,
            Column: column
        };
        let Result: GroupModel | undefined;

        await Axios.post(GroupRoutes.create(sessionId, phaseId, boardId), DATA).then(res => {
            if (res.status === 201) {
                Result = res.data as GroupModel;
            }
        }, err => {
            if (err.status === 400) {
                alert(err.error);
            }
        });

        return Result;
    },

    async archive(sessionId: string, phaseId: string, boardId: string, groupId: string) {
        let Result: boolean = false;

        await Axios.delete(GroupRoutes.archive(sessionId, phaseId, boardId, groupId)).then(res => {
            if (res.status === 204) {
                Result = true;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },

    collapse: {
        single: async (sessionId: string, phaseId: string, boardId: string, groupId: string) => {
            let Result: boolean = false;

            await Axios.patch(GroupRoutes.collapse(sessionId, phaseId, boardId, groupId)).then(res => {
                if (res.status === 200) {
                    Result = true;
                }
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        all: async (sessionId: string, phaseId: string, boardId: string) => {
            let Result: Array<GroupModel> = [];

            await Axios.patch(GroupRoutes.collapseAll(sessionId, phaseId, boardId)).then(res => {
                if (res.status === 200) {
                    Result = res.data;
                }
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    },

    move: async (sessionId: string, phaseId: string, boardId: string, groupId: string, targetIndex: number) => {
        const DATA = {
            TargetIndex: targetIndex
        };
        let Result: Array<GroupModel> = [];
        await Axios.patch(GroupRoutes.move(sessionId, phaseId, boardId, groupId), DATA).then(res => {
            if (res.status === 200) {
                Result = res.data;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },

    setColumn: async (sessionId: string, phaseId: string, boardId: string, groupId: string, targetColumn: number, targetIndex: number) => {
        let Result: Array<GroupModel> = [];

        await Axios.patch(GroupRoutes.setColumn(sessionId, phaseId, boardId, groupId, targetColumn, targetIndex)).then(res => {
            if (res.status === 200) {
                Result = res.data;
            }
        }, err => {
            alert(err.error);
        });

        return Result;
    },

    async rename(sessionId: string, phaseId: string, boardId: string, groupId: string, name: string) {
        const DATA = {
            Name: name
        };
        let Result: GroupModel | null = null;

        await Axios.patch(GroupRoutes.rename(sessionId, phaseId, boardId, groupId), DATA).then(res => {
            Result = res.data;
        }, err => {
            alert(err.error);
        });

        return Result;
    },

    async setColor(sessionId: string, phaseId: string, boardId: string, groupId: string, hexColor: string) {
        const DATA = {
            ColorInHex: hexColor
        };
        let Result: GroupModel | null = null;

        await Axios.patch(GroupRoutes.setColor(sessionId, phaseId, boardId, groupId), DATA).then(res => {
            Result = res.data;
        }, err => {
            alert(err.error);
        });

        return Result;
    },

    get: {
        byBoard: async (sessionId: string, phaseId: string, boardId: string) => {
            let Result: Array<GroupModel> = [];

            await Axios.get(GroupRoutes.getByBoard(sessionId, phaseId, boardId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        bySession: async (sessionId: string) => {
            let Result: Array<GroupModel> = [];

            await Axios.get(GroupRoutes.getAll(sessionId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        },
        byId: async (sessionId: string, phaseId: string, boardId: string, groupId: string) => {
            let Result: GroupModel | null = null;

            await Axios.get(GroupRoutes.getById(sessionId, phaseId, boardId, groupId)).then(res => {
                Result = res.data;
            }, err => {
                alert(err.error);
            });

            return Result;
        }
    }
};