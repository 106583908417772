import React, { Component } from "react";
import { styled, alpha } from "@mui/material/styles";
import Theme from "../../../Theme/ThemeOptions";

interface IProps {
    style: React.CSSProperties,
}

interface IState {
}

interface IDiv extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}

const Container = styled(({ ...divProps }: IDiv) => (
    <div {...divProps} />
))<IDiv>(() => ({
    margin: 0,
    position: "fixed",
    padding: "0.5rem 1rem",
    display: "flex",
    zIndex: 100,
    height: "56px",
    flexDirection: "row",
    borderRadius: "2rem",
    background: Theme.palette.background.paper,
    boxShadow: `0 0 10px 10px ${alpha(Theme.palette.text.primary, 0.15)}`
}));

export class Toolbar extends Component<IProps, IState> {
    render() {
        return (
            <Container {...this.props}>
                {this.props.children}
            </Container>
        );
    }
}