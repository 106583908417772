
import Theme from "../../../Theme/ThemeOptions";
import { TextButton, PrimaryButton } from "../General/Button";

import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Typography, TextField, InputAdornment, ButtonGroup, IconButton } from "@mui/material";
import {InputModel} from "../../../Models/InputModel"
import {VoteModel}  from "../../../Models/VoteModel";
import { VoteController } from "../../../Controllers/VoteController";
import { OptionController } from "../../../Controllers/OptionController";


//TODO: Add check to see what kind of vote it is
//TODO: Add Button Styles

interface IProps {
    type: "slider" | "points",
    selected?: InputModel[],
    onClose: () => void,
    onRemove: (input: string) => void,
    session: string,
    phase: string,
    board: string,

}
interface IState { }

export class Ballot extends React.Component<IProps, IState> {
    state = {
        min: "1",
        minDescription: "Not important",
        max: "5",
        maxDescription: "Very important",
        time: "",
        title: "",
        description: ""
    }


    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const Name = event.currentTarget.name;
        const Value = event.currentTarget.value;

        this.setState({
            ...this.state,
            [Name]: Value
        });

    }


    onSubmit = (event: React.MouseEvent<HTMLButtonElement | MouseEvent> | React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (this.props.selected && this.props.selected.length > 1)
            this.CreateVote();
    }

    async CreateVote() {
        const VOTE = await VoteController.create(this.props.session, this.props.phase, this.props.board, this.props.type, this.state.title, this.state.description, parseInt(this.state.min), this.state.minDescription, parseInt(this.state.max), this.state.maxDescription);

        const SELECTED = this.props.selected;
        if (VOTE && SELECTED) {
            for (let i = 0; i < SELECTED.length; i++) {
                const OPTION = await OptionController.create(this.props.session, this.props.phase, this.props.board, VOTE.id, SELECTED[i].id, SELECTED[i].title);
            }
        }
        this.props.onClose();
    }


    render() {
        return (
            <div style={{padding: "15px"}}>
                <form onSubmit={this.onSubmit}>

                    <Typography variant="h4">
                        Voting - {this.props.type}
                    </Typography>

                    <div style={{padding: "10px"}}>
                        <TextField
                            InputLabelProps={{ required: false }}
                            label="Title"
                            name="title"
                            onChange={this.handleChange}
                            size="small"
                            type="text"
                            value={this.state.title}
                            variant="outlined" />

                        <TextField
                            InputLabelProps={{ required: false }}
                            label="Description"
                            multiline
                            name="description"
                            onChange={this.handleChange}
                            required
                            type="text"
                            value={this.state.description}
                            variant="outlined" />

                    </div>


                    <div style={{padding: "10px"}}>
                        <Typography variant="overline">
                            Minimum Value
                        </Typography>

                        <div>
                            <TextField
                                InputLabelProps={{ required: false }}
                                name="min"
                                onChange={this.handleChange}
                                required
                                size="small"
                                type="tel"
                                value={this.state.min}
                                variant="outlined" />

                            <TextField
                                InputLabelProps={{ required: false }}
                                name="minDescription"
                                onChange={this.handleChange}
                                required
                                size="small"
                                type="text"
                                value={this.state.minDescription}
                                variant="outlined" />
                        </div>
                    </div>
                    <div style={{padding: "10px"}}>
                        <Typography variant="overline">
                            Maximum Value
                        </Typography>

                        <div>
                            <TextField
                                InputLabelProps={{ required: false }}
                                name="max"
                                onChange={this.handleChange}
                                required
                                size="small"
                                type="tel"
                                value={this.state.max}
                                variant="outlined" />

                            <TextField
                                InputLabelProps={{ required: false }}
                                name="minDescription"
                                onChange={this.handleChange}
                                required
                                size="small"
                                type="text"
                                value={this.state.maxDescription}
                                variant="outlined" />
                        </div>

                    </div>

                    <div style={{padding: "10px"}}>
                        <Typography variant="overline">
                            Options
                        </Typography>

                        {
                            this.props.selected
                            && this.props.selected.map((option, index) =>
                                <div style={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", marginLeft: "20px"}}
                                    key={`${option.id}-${option.groupId}-${index}`}>

                                <Typography variant="body2">
                                    {index + 1}.
                                </Typography>
                                <Typography variant="subtitle2">
                                    {option.title}
                                </Typography>
                                <div>
                                    <TextButton size="small" sx={{color: Theme.palette.text.disabled}}
                                        onClick={() => this.props.onRemove(option.id)}>
                                            X
                                            </TextButton>
                                </div>
                            </div>
                        )}
                    </div>

                    <div style={{padding: "10px"}}>
                        <Typography variant="overline">
                            Timer
                        </Typography>

                        <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                            <TextField
                                InputProps={{
                                    startAdornment: (<InputAdornment
                                        position="start">
                                        <span className="material-icons-outlined">timer</span>
                                    </InputAdornment>)
                                }}
                                name="time"
                                onChange={this.handleChange}
                                placeholder="..."
                                size="small"
                                type="tel"
                                value={this.state.time}
                                variant="outlined" />
                            <Typography variant="body2" sx={{color: Theme.palette.text.secondary}}>
                                enter whole minutes(optional)
                            </Typography>
                        </div>
                    </div>


                    <div style={{padding: "10px", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
                        <div>
                            <TextButton
                                onClick={() => this.props.onClose()}>
                                cancel
                            </TextButton>
                        </div>
                        <div>
                            <PrimaryButton
                                onClick={(e) => this.onSubmit(e)}>
                                Start Vote!
                            </PrimaryButton>
                        </div>


                    </div>
                </form>
            </div>
        );
    }
}