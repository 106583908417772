import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Typography, TextField, InputAdornment, Box } from "@mui/material";
import { LoginModal } from "./Components/Landing/Login";
import { PrimaryButton as Button } from "./Components/General/Button";
import Theme from "../Theme/ThemeOptions";


interface IProps extends RouteComponentProps { }

interface IState {

    loggedIn: boolean,
    loginModal: boolean;
}

export class LandingView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loggedIn: false,
            loginModal: false
        };
    }


    handleModal = {
        open: () => {
            //TODO: This should open the modal
            //TODO: It does this for Developer Testing
            //TODO: And to remove any chance that Security isn't forgotten about before releasing the build.
            //localStorage.setItem("user", "f0f26d5a-9ab5-4f59-a878-c2319e65c559");
            //localStorage.setItem("name", "The Master");
            this.setState({ loginModal: true });

            //alert("Fake logged into test@notPrivate.temp\n" +
            //    "Security has yet to be implemented, personal accounts won't be accepted before they are secure.\n This temporary account is ONLY for Development and will be deleted along with all the data.");

            //this.props.history.push("/dashboard");
        },
        close: (loggedIn: boolean) => {
            const LoggedIn = loggedIn; //TODO: Check for log in
            if (LoggedIn) {
                this.props.history.push("/dashboard");
            }
            this.setState({
                loginModal: false,
                loggedIn: LoggedIn
            });
        }
    };


    render() {
        return (
            <Box
                sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, padding: 0, margin: 0, height: "100%", width: "100%" }}>

                <Box
                    sx={{ position: "relative", width: "calc(100% - 90px - 140px)", height: "90px", margin: "40px 90px 40px 140px" }}>
                    <Button
                        onClick={(event) =>
                        this.handleModal.open()}
                        sx={{ background: Theme.palette.text.primary, color: Theme.palette.background.paper, float: "right", display: "inline-block", position: "relative", top: "50%", transform: "translateY(-50%)" }}
                        type="submit">
                        Login / Sign up
                    </Button>

                    <Typography
                        sx={{ lineHeight: "90px", textAlign: "center", margin: "0 20px", float: "right" }}
                        variant="subtitle2">
                        Contact us
                    </Typography>

                    <Typography
                        sx={{ lineHeight: "90px", textAlign: "center", margin: "0 20px", float: "right" }}
                        variant="subtitle2">
                        Pricing
                    </Typography>

                    <Typography
                        sx={{ lineHeight: "90px", textAlign: "center", margin: "0 20px", float: "right" }}
                        variant="subtitle2">
                        About
                    </Typography>

                    <Typography
                        sx={{ color: Theme.palette.primary.light, lineHeight: "90px", textAlign: "center", margin: "0 0 0 20px", float: "left" }}
                        variant="h1">
                        .co
                    </Typography>

                    <Typography
                        sx={{ lineHeight: "90px", textAlign: "center", margin: "0 20px 0 0", float: "left" }}
                        variant="h1">
                        boost
                    </Typography>

                </Box>

                <Box
                    sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", height: "calc(100% - 90px)", flexDirection: "row"}}>
                    <Box
                        sx={{display: "flex", flexDirection: "column",  }}>
                        <Typography sx={{marginBottom: "20px"}} variant="h2">
                            The Tool for Digital Co-Creation
                        </Typography>

                        <Typography sx={{marginBottom: "40px"}} variant="body1">
                            Enter code to co-create and innovate with your team members
                        </Typography>

                        <form>
                            <TextField
                                id="code"
                                sx={{borderRadius: "3rem", boxShadow: `0 0 8px 16px ${Theme.palette.primary.main}`, outline: "0", '& .MuiFilledInput-root': {
                                    borderRadius: "3rem",
                                    background: Theme.palette.background.paper,
                                    outline: 0,
                                    border: "0",
                                    margin: "0 20px",
                                    ':hover': {
                                        border: "none !important",
                                    },
                                    ':active': {
                                    border: "none !important",
                                }
                                }, '& .MuiFilledInput-input': {
                                    outline: 0,
                                    border: "none !important",
                                    padding: "12px",
                                    ':hover': {
                                        border: "none !important",
                                    },
                                    ':active': {
                                    border: "none !important",
                                }
                                }, ':hover': {
                                    boxShadow: `0 0 4px 16px ${Theme.palette.primary.light}`,

                                    border: "none !important",
                                },':active': {
                                    boxShadow: `0 0 4px 16px ${Theme.palette.primary.light}`,

                                    border: "none !important",
                                }
                                }}
                                InputProps={{
                                endAdornment: <InputAdornment
                                                  position="end"
                                                  style={{ borderRadius: "3rem" }}>
                                                  <Button
                                                      onClick={(event) => event.preventDefault()}
                                                      type="submit">
                                                      Join Session
                                                  </Button>
                                              </InputAdornment>
                            }}
                                name="code"
                                variant="filled" placeholder="Type session code..." />
                        </form>
                    </Box>
                    <Box
                        sx={{ }}>
                        <span style={{fontSize: "40rem"}} class="material-icons">
                            smart_toy
                        </span>
                    </Box>
                </Box>
                { /*<Background*/
                }
                { /*    id="background-image"*/
                }
                { /*    name="background-image" />*/
                }
                {this.state.loginModal
                    && <LoginModal
                           onClose={this.handleModal.close}
                           open={this.state.loginModal} />
                }
            </Box>
        );
    }
}